import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import Breadcrumb from '../components/Breadcrumb';
import FooterOne from '../components/FooterOne';
import CareerArea from '../components/CareerArea';
import axios from 'axios';
import { apiBaseUrl } from '../config/config';


const Career = () => {
  const [header, setHeader] = useState([]);

  const fetchHeader = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl + "headers"
      );

      setHeader(response.data.data[0].attributes);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchHeader();
  }, [])

  return (
    <>
      {/* Navigation Bar */}
      <NavBar header={header} />

      {/* Breadcrumb */}
      <Breadcrumb title={'Career'} />

      {/* PriceAreaOne */}
      <CareerArea />

      {/* Footer One */}
      <FooterOne header={header} />
    </>
  );
};

export default Career;
