import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import { apiBaseUrl, IMAGE_URL } from '../config/config';

const TestimonialTwo = () => {
  const [testimonialData, setTestimonialData] = useState([]);
  const [maxHeight, setMaxHeight] = useState(0);
  const testimonialRefs = useRef([]);

  // Fetch testimonial data from the API
  const fetchTestimonialData = async () => {
    try {
      const response = await axios.get(apiBaseUrl + 'testimonials?populate=*');
      setTestimonialData(response.data.data);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
    }
  };

  // Fetch testimonials on component mount
  useEffect(() => {
    fetchTestimonialData();
  }, []);

  // Update the max height after the testimonials have been loaded
  useEffect(() => {
    if (testimonialData.length > 0) {
      const heights = testimonialRefs.current.map(ref => ref?.clientHeight || 0);
      setMaxHeight(Math.max(...heights));
    }
  }, [testimonialData]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <div className="testimonial-area pd-top-120"
        style={{ marginTop: window.innerWidth < 576 ? '20px' : '0px' }} // Adding marginTop for mobile view
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center">
                <h6 className="sub-title">CLIENT TALK</h6>
                <h2 className="title">
                  What Client <span>Think</span> About Us?
                </h2>
              </div>
            </div>
          </div>
          <div className="testimonial-slider-2 slider-control-dots">
            <Slider {...settings}>
              {testimonialData.length > 0 ? (
                testimonialData.map((testimonial, index) => (
                  <div className="item" key={testimonial.id}>
                    <div
                      className="single-testimonial-inner style-2"
                      ref={(el) => (testimonialRefs.current[index] = el)}
                      style={{
                        minHeight: `${maxHeight}px`,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: '20px',
                        boxSizing: 'border-box',
                        // Mobile adjustments
                        paddingBottom: window.innerWidth < 768 ? '30px' : '20px',
                        marginBottom: window.innerWidth < 768 ? '15px' : '0',
                        marginTop: window.innerWidth < 576 ? '15px' : '0', // Add marginTop on mobile
                      }}
                    >
                      <div className="row h-100 align-items-start">
                        <div
                          className="col-sm-12 d-flex align-items-center"
                          style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            textAlign: "justify", // Keep text alignment as justify
                            fontSize: window.innerWidth < 576 ? '14px' : 'inherit',
                          }}
                        >
                          {/* Use dangerouslySetInnerHTML to render HTML content */}
                          <p className="designation mb-0" dangerouslySetInnerHTML={{ __html: testimonial.attributes.Text }} />

                          {/* Image positioned below the text for mobile view */}
                          <div
                            className="thumb d-inline-block"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              margin: '15px 0', // Add margin to separate image from text
                            }}
                          >
                            <img
                              src={IMAGE_URL + testimonial.attributes.image.data.attributes.url}
                              alt="Author"
                              style={{
                                height: window.innerWidth < 576 ? '80px' : '100px',
                                width: window.innerWidth < 576 ? '80px' : '100px',
                                objectFit: 'cover',
                                borderRadius: '50%',
                              }}
                            />
                          </div>

                          {/* Name and designation below the image */}
                          <h5 className="mb-0" style={{ marginTop: '15px' }}>
                            <strong>{testimonial.attributes.Name}</strong> {/* Making name bold */}
                            <span style={{ fontWeight: 'normal', marginLeft: '5px', fontSize: "18px" }}>
                              {testimonial.attributes.Designation}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>Loading testimonials...</p>
              )}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialTwo;
