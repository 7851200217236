import React from "react";
import { useState, useEffect } from 'react';
import BlogGroup from "../components/BlogGroup";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import axios from 'axios';
import { apiBaseUrl } from '../config/config';
const Blog = () => {

  const [header, setHeader] = useState([]);

  const fetchHeader = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl + "headers?populate=*"
      );

      setHeader(response.data.data[0].attributes);

    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchHeader();
  }, [])
  return (
    <>
      {/* Navigation Bar */}
      <NavBar header={header} />

      {/* Navigation Bar */}
      <Breadcrumb title="Blogs" pageLink="blog" blogBanner={header} />

      {/* Blog Group */}
      <BlogGroup />

      {/* Footer One */}
      <FooterOne header={header} />
    </>
  );
};

export default Blog;
