import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IMAGE_URL, apiBaseUrl } from '../config/config';




const AboutAreaOne = () => {

  const [aboutData, setAboutData] = useState([]);
  // const [logo, setLogo] = useState([]);
  const [image1, setImage1] = useState([]);
  //const [image2, setImage2] = useState([]);

  const fetchAboutData = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl + "home-abouts?populate=*"
      );

      setAboutData(response.data.data[0].attributes);
      // setLogo(response.data.data[0].attributes.Logo.data.attributes.url);
      setImage1(response.data.data[0].attributes.Image1.data.attributes.url);
      //setImage2(response.data.data[0].attributes.Image2.data.attributes.url);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    fetchAboutData();
  }, [])
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                />
                <img
                  className='animate-img-2 left_image_bounce'
                  src='assets/img/about/3.png'
                  alt='img'
                />
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                {/* <img src={IMAGE_URL + image2} alt="About" /> */}
                <img src={IMAGE_URL + image1} alt="About" style={{ width: '100%', height: '1100px', objectFit: 'cover' }} />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h6 className='sub-title'> <span className="sub-title">{aboutData.Title1}</span></h6>
                <h2 className='title'>{aboutData.Title2}</h2>
                <br></br>
                <p style={{ textAlign: 'justify' }}> {aboutData.Description} </p>
                <div className='row'>
                  <div className='col-12 mb-4'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/2.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>{aboutData.AboutUsSubSection1}</h5>
                        <p style={{ color: "black", fontWeight: "700" }}>
                          {aboutData.AboutUsSubSection1Heading}
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          {aboutData.AboutUsSubSection1Content}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 mb-4'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/3.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>{aboutData.AboutUsSubSection2}</h5>
                        <p style={{ color: "black", fontWeight: "700" }}>
                          {aboutData.AboutUsSubSection2Heading}
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          {aboutData.AboutUsSubSection2Content}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
