import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { apiBaseUrl, IMAGE_URL } from '../config/config';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPinterest,
  FaPlus,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const TeamDetailsGroup = () => {
  const { slug } = useParams();
  const [teamData, setTeamData] = useState(null);

  const fetchTeamData = async () => {
    try {
      const response = await axios.get(apiBaseUrl + `team-members/${slug}?populate=*`);
      setTeamData(response.data.data.attributes);
      console.log(response.data.data.attributes);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTeamData();
  }, [slug]);

  if (!teamData) {
    return <p>Loading...</p>; // Display a loading message or a placeholder
  }

  return (
    <>
      {/* ================= TeamDetailsGroup Start =================*/}
      <div className='team-blog-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='team-details-page'>
            <div className='row'>
              <div className='col-lg-5 text-center'>
                <div className='thumb mb-4 mb-lg-0'>
                  <img
                    src={teamData?.Image?.data?.attributes?.url ? IMAGE_URL + teamData.Image.data.attributes.url : ""}
                    alt="img"
                  />
                </div>
              </div>
              <div className='col-lg-7 align-self-center ps-xl-5'>
                <h3 className='mb-2'>{teamData?.Name}</h3>
                <span className='designation'>{teamData?.Designation}</span>
                <div className='details mt-4'>
                  <p style={{ textAlign: "justify" }}>{teamData?.Description}</p>
                </div>
                <ul className='social-media style-base mt-4 pt-2'>
                  {/* {teamData?.Facebook && (
                    <li>
                      <Link to={teamData.Facebook}>
                        <FaFacebookF aria-hidden='true' />
                      </Link>
                    </li>
                  )} */}
                  {/* {teamData?.Twitter && (
                    <li>
                      <Link to={teamData.Twitter} target='_blank'>
                        <FaTwitter aria-hidden='true' />
                      </Link>
                    </li>
                  )} */}
                  {/* {teamData?.Instagram && (
                    <li>
                      <Link to={teamData.Instagram}>
                        <FaInstagram aria-hidden='true' />
                      </Link>
                    </li>
                  )} */}
                  {teamData?.Linkedin && (
                    <li>
                      <Link to={teamData.Linkedin} target='_blank'>
                        <FaLinkedinIn aria-hidden='true' />
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* service area start */}
      {/* ... */}
      {/* ================= TeamDetailsGroup End =================*/}
    </>
  );
};

export default TeamDetailsGroup;
