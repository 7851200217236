import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import TeamDetailsGroup from "../components/TeamDetailsGroup";
import axios from 'axios';
import { apiBaseUrl } from '../config/config';

const TeamDetails = () => {

  const [header, setHeader] = useState([]);

  const fetchHeader = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl + "headers?populate*"
      );

      setHeader(response.data.data[0].attributes);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchHeader();
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      {/* Navigation Bar */}
      <NavBar header={header} />

      {/* Breadcrumb */}
      <Breadcrumb title={"Team Details"} />

      {/* Team Details Group */}
      <TeamDetailsGroup />

      {/* Footer One */}
      <FooterOne header={header} />
    </>
  );
};

export default TeamDetails;
