import React, { useEffect, useState, useRef } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IMAGE_URL, apiBaseUrl } from "../config/config";
import axios from "axios";
import FooterOne from "./FooterOne";

const ServiceDetailsArea = ({ header, service, serviceId }) => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [loading, setLoading] = useState(true);
  const [sidebarStyle, setSidebarStyle] = useState({});
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);

  const fetchData = async (id) => {
    try {
      const response = await axios.get(`${apiBaseUrl}services/${id}?populate=*`);
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchAllServices = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}services?populate=*`);
      setServices(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllServices();
    if (id) {
      setLoading(true);
      fetchData(id);
      setSelectedService(id);
    }
  }, [id]);

  useEffect(() => {
    const handleScroll = () => {
      if (!sidebarRef.current || !contentRef.current) return;

      const footer = document.querySelector('footer');
      const footerTop = footer ? footer.getBoundingClientRect().top : window.innerHeight;
      const sidebarRect = sidebarRef.current.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();
      const sidebarHeight = sidebarRect.height;
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      if (window.innerWidth <= 992) {
        setSidebarStyle({});
        return;
      }

      const contentBottom = contentRect.bottom;
      const sidebarBottom = sidebarRect.bottom;
      const buffer = 50;

      if (scrollY < 320) {
        setSidebarStyle({
          position: 'static',
          width: '100%',
          opacity: 1,
          pointerEvents: 'auto'
        });
      } else if (contentBottom > sidebarBottom + buffer) {
        setSidebarStyle({
          position: 'fixed',
          top: '20px',
          width: sidebarRect.width + 'px',
          opacity: 1,
          pointerEvents: 'auto'
        });
      } else {
        setSidebarStyle({
          position: 'fixed',
          top: '10px',
          width: sidebarRect.width + 'px',
          opacity: 0,
          pointerEvents: 'auto'
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const handleServiceClick = (slug) => {
    setSelectedService(slug);
    navigate(`/service-details/${slug}`, { replace: true });
    setSidebarStyle({
      position: 'static',
      width: '100%',
      opacity: 1,
      pointerEvents: 'auto'
    });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <style>
        {`
          .service-detail-banner {
            width: 100%;
            height: 520px;
            object-fit: cover;
            border-radius: 10px;
          }
          @media (max-width: 768px) {
            .service-detail-banner {
              height: auto;
              max-height: 300px;
            }
            .service-description-content {
              text-align: left !important; /* Override justify on mobile */
            }
          }
          @media (max-width: 480px) {
            .service-detail-banner {
              height: auto;
              max-height: 200px;
            }
            .service-description-content {
              text-align: left !important; /* For smaller screens */
            }
          }
        `}
      </style>

      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div
                ref={sidebarRef}
                className="td-sidebar service-sidebar"
                style={{
                  ...sidebarStyle,
                  transition: 'opacity 0.3s ease-out, top 0.3s ease-out'
                }}
              >
                <div className="widget widget_catagory">
                  <h5 className="widget-title">
                    <FaArrowRight /> All Services list
                  </h5>
                  <ul className="catagory-items">
                    {services.map((service) => (
                      <li key={service.id}>
                        <Link
                          to={`/service-details/${service.attributes.slug}`}
                          onClick={() => handleServiceClick(service.attributes.slug)}
                          style={{
                            fontWeight: selectedService === service.attributes.slug ? 'bold' : 'normal',
                            color: selectedService === service.attributes.slug ? "#246bfd" : "black"
                          }}
                        >
                          {service.attributes.Title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8" ref={contentRef}>
              {data ? (
                <div className="blog-details-page-content">
                  <div className="single-blog-inner mb-0">
                    <div className="">
                      <img
                        src={
                          data?.attributes?.ServiceDetailBanner?.data?.attributes?.url
                            ? IMAGE_URL + data?.attributes?.ServiceDetailBanner?.data?.attributes?.url
                            : ""
                        }
                        alt="Service Details"
                        className="service-detail-banner"
                      />
                    </div>
                    <br />
                    <div className="details">
                      <h4>{data.attributes.Title}</h4>
                      <div
                        dangerouslySetInnerHTML={{ __html: data.attributes.Description }}
                        className="service-description-content"
                        style={{ textAlign: "justify" }}
                      />

                    </div>
                  </div>
                </div>
              ) : (
                <p>No service details found.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <FooterOne
        header={header}
        selectedService={selectedService}
        handleServiceClick={handleServiceClick}
      />
    </>
  );
};

export default ServiceDetailsArea;
