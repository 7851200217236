import React, { useState, useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { apiBaseUrl } from '../config/config';
import { Fade } from 'react-awesome-reveal';

const ServiceAreaGroupThree = () => {

  const [serviceData, setServiceData] = useState([]);
  const [featureData, setFeatureData] = useState([]);

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}services?populate=*`);
      setServiceData(response.data.data);
      console.log("response", response);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  const fetchFeatureData = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}service-detail-features`);
      setFeatureData(response.data.data);
      console.log("response", response);
    } catch (error) {
      console.error('Error fetching feature data:', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchServices();
    fetchFeatureData();
  }, []);

  const truncateDescription = (text) => {
    if (!text) return '';
    const lines = text.split('\n');
    if (lines.length > 5) {
      return lines.slice(0, 5).join('\n') + '...';
    }
    return text;
  };

  return (
    <>
      <div className="service-area bg-relative pd-top-120">
        <div className="container">
          <div className="row">
            {serviceData.slice(0, 6).map((service, index) => {
              const description = service.attributes.ShortDescription || service.attributes.Description || 'Service Description';

              return (
                <div className="col-lg-4 col-md-6" key={service.id || index}>
                  <div className="single-work-process-inner">
                    <div className="thumb mb-3">
                      <img
                        src={`assets/img/icon/${index + 1 + 15}.svg`}
                        alt="Service Icon"
                      />
                    </div>
                    <div className="details">
                      <h5 className="mb-3">{service.attributes.Title || 'Service Title'}</h5>
                      <p className="content mb-3 truncated-text" style={{ maxHeight: '160px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line' }}>
                        {truncateDescription(description)}
                      </p>
                      <Link className="read-more-text" to={`/service-details/${service.attributes.slug}`}>
                        Read More <FaArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row pt-25">
            {/* {featureData.slice(0, 3).map(feature => (
              <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6" key={feature.id}>
                <Fade delay={feature.attributes.Delay}>
                  <div className="feature-item">
                    <div className="icon">
                      <i className={feature.attributes.Icon}></i>
                    </div>
                    <div className="feature-content">
                      <h5>{feature.attributes.Title}</h5>
                      <p className="truncated-text" style={{ maxHeight: '110px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line' }}>
                        {truncateDescription(feature.attributes.Description)}
                      </p>
                      <Link to={`/service-details/${feature.attributes.slug}`} className="learn-more">
                        Read More <i className={feature.attributes.Icon2}></i>
                      </Link>
                    </div>
                  </div>
                </Fade>
              </div>
            ))} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceAreaGroupThree;
