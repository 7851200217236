import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IMAGE_URL, apiBaseUrl } from '../config/config';
import { FaCalendarAlt, FaRegFolderOpen, FaRegUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const BlogAreaOne = (props) => {
  const { blogSpace } = props;
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "blog-page-contents?populate=*");

      const filteredData = response.data.data.filter(item => item.attributes.MainBlog === true);

      setData(response.data.data.slice(0, 3));

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/*===================== Blog Area One start =====================*/}
      <div className="blog-area pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">Recent Blog</h6>
            <h2 className="title">
              Latest <span>Blog</span> from Us
            </h2>
          </div>
          <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
            {data.map(blog => (
              <div className="col-lg-4 col-md-6" key={blog.id} style={{ display: 'flex' }}>
                <div className="single-blog-list" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <div className="thumb">
                    {blog.attributes.SingleBlog ? (
                      <img
                        src={blog.attributes.SingleBlog?.data?.attributes?.url ? IMAGE_URL + blog.attributes.SingleBlog?.data?.attributes?.url : ""}
                        alt="img"
                        style={{ width: '100%', height: '240px', objectFit: 'cover', borderRadius: '10px' }}
                      />
                    ) : null}
                  </div>

                  <div className="details" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <p className="date mb-3">
                      <FaCalendarAlt />
                      {new Date(blog.attributes.Date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                    </p>
                    <h5>
                      <Link to={`/blog-details/${blog.attributes.slug}`} style={{ color: 'black', textDecoration: 'none' }}>
                        {blog.attributes.Title}
                      </Link>
                    </h5>
                    <div className="meta mt-auto">
                      <div className="row">
                        <div className="col-6">
                          <p>
                            <FaRegUser />
                            {blog.attributes.Author}
                          </p>
                        </div>
                        <div className="col-6 text-end">
                          <p style={{ marginLeft: "-40px" }}>
                            <FaRegFolderOpen />
                            {blog.attributes.Category}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* ===================== BlogAreaOne End =====================*/}
    </>
  );
};

export default BlogAreaOne;
