import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  FaArrowRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaPhoneAlt,
  FaTwitter,
  FaMapMarkerAlt,
  FaClock,
} from 'react-icons/fa';
import { IMAGE_URL, apiBaseUrl } from '../config/config';
import subscribeImage from '../Images/6.png'
import axios from 'axios';

const FooterOne = ({ header, selectedService, handleServiceClick }) => {
  const [logoData, setLogoData] = useState(null);
  const [footer, setFooter] = useState([]);
  const [services, setServices] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const fetchFooterData = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "headers");
      setFooter(response.data.data[0].attributes);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLogoData = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "logos?populate=*");
      if (response.data.data.length > 0) {
        const logoUrl = response.data.data[0].attributes;
        setLogoData(logoUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "services?populate=*");
      setServices(response.data.data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  useEffect(() => {
    fetchFooterData();
    fetchLogoData();
    fetchServices();
  }, []);

  const getSocialLink = (socialKey) => {
    if (footer && footer[socialKey]) {
      return footer[socialKey];
    } else if (header && header[socialKey]) {
      return header[socialKey];
    } else {
      return '#';
    }
  };

  const handleAddressClick = () => {
    if (location.pathname === '/contact') {
      // Scroll to the map section if already on the contact page
      const mapElement = document.getElementById('map-section');
      if (mapElement) {
        mapElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Navigate to contact page and scroll to the map section
      navigate('/contact');
    }
  };

  return (
    <>
      <footer className="footer-area bg-black bg-cover">
        <div className="footer-subscribe">
          <div className="container">
            <div className="footer-subscribe-inner bg-cover" style={{ backgroundImage: `url(${subscribeImage})` }}>
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="mb-lg-0 mb-3">Subscribe To Our Blogs</h2>
                </div>
                <div className="col-lg-6 align-self-center text-lg-end">
                  <input type="text" placeholder="Coming Soon !" />
                  <Link className="btn btn-black border-radius-0" to="#">
                    Submit now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-widget-area bgs-cover pt-100 pb-50" style={{ backgroundImage: "url(/images/footer/footer-bg-dots.png)" }}>
          <div className="container" style={{ marginBottom: '-70px' }}>
            <div className="row" >
              <div className="col-lg-3 col-md-6 " style={{ marginRight: '100px' }}>
                <div className="widget widget_about">
                  <div className="thumb footer-logo mb-35">
                    <Link to="/#">
                      {logoData ? (
                        <img style={{
                          maxWidth: "200px",
                          maxHeight: "auto",
                          width: "100%",
                          height: "auto"
                        }} src={logoData?.Logo?.data?.attributes?.url ? IMAGE_URL + logoData?.Logo?.data?.attributes?.url : ""} alt="Logo" />
                      ) : null}
                    </Link>
                  </div>
                  <div className="widget">
                    <p style={{ marginBottom: '10px', color: "white" }}>
                      Igniters Hub is a leading IT services company, based in National Capital Region (NCR) Delhi. Our focus is on transforming organizations through Digital{' '}
                      <Link
                        to="/about#"
                        className="read-more"
                        style={{ textDecoration: 'none', color: 'grey' }}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        &nbsp; read more...
                      </Link>
                    </p>
                    {/* <p className="mt-3 text-white">
                      <FaPhoneAlt style={{ marginRight: '8px' }} /> {header && header.Phone ? header.Phone : '(+888) 123 456 765'}
                    </p>
                    <p className="mt-2 text-white">
                      <FaEnvelope style={{ marginRight: '8px' }} />  {header && header.Email ? header.Email : 'info@example.com'}
                    </p> */}
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6" style={{ flex: 1, marginRight: '10px', marginLeft: "10px" }}>
                <div className="widget widget_nav_menu">
                  <h4 className="widget-title">Our Service</h4>
                  <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                    {services.map((service) => (
                      <li key={service.id} style={{ marginBottom: '10px', display: 'flex', alignItems: 'flex-start' }}>
                        <span style={{ display: 'inline-block', minWidth: '20px' }}>
                          <FaArrowRight />
                        </span>
                        <span style={{ display: 'inline-block', marginLeft: '5px', flex: 1 }}>
                          <Link
                            to={`/service-details/${service.attributes.slug}`}
                            onClick={() => handleServiceClick(service.attributes.slug)}
                            style={{
                              color: selectedService === service.attributes.slug ? 'blue' : 'inherit',
                              textDecoration: 'none',
                            }}
                          >
                            {service.attributes.Title}
                          </Link>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6" style={{ flex: 1, marginRight: '0' }}>
                <div className="widget widget_nav_menu">
                  <h4 className="widget-title">Contacts</h4>
                  <ul className="list-style-two" style={{ listStyle: 'none', paddingLeft: 0 }}>
                    <li style={{ marginBottom: '10px', display: 'flex', alignItems: 'flex-start' }}>
                      <span style={{ display: 'inline-block', minWidth: '20px' }}>
                        <FaMapMarkerAlt />
                      </span>
                      <span style={{ display: 'inline-block', marginLeft: '5px', flex: 1 }}>
                        <Link to="/contact#map-section" style={{ textDecoration: 'none' }} onClick={handleAddressClick}>
                          {footer.Address ? footer.Address : 'Your Address'}
                        </Link>
                      </span>
                    </li>

                    <li style={{ marginBottom: '10px', display: 'flex', alignItems: 'flex-start' }}>
                      <span style={{ display: 'inline-block', minWidth: '20px' }}>
                        <FaClock />
                      </span>
                      <span style={{ display: 'inline-block', marginLeft: '5px', flex: 1 }}>
                        {footer.Timings}
                      </span>
                    </li>
                    <li style={{ marginBottom: '10px', display: 'flex', alignItems: 'flex-start' }}>
                      <span style={{ display: 'inline-block', minWidth: '20px' }}>
                        <FaPhoneAlt />
                      </span>
                      <span style={{ display: 'inline-block', marginLeft: '5px', flex: 1 }}>
                        <Link to="tel:+012-345-6789" style={{ textDecoration: 'none' }}>
                          {footer.Phone}
                        </Link>
                      </span>
                    </li>
                    <li style={{ marginBottom: '10px', display: 'flex', alignItems: 'flex-start' }}>
                      <span style={{ display: 'inline-block', minWidth: '20px' }}>
                        <FaEnvelope />
                      </span>
                      <span style={{ display: 'inline-block', marginLeft: '5px', flex: 1 }}>
                        <Link to="mailto:sales@ignitershub.com" style={{ textTransform: 'lowercase', textDecoration: 'none' }}>
                          {footer.Email}
                        </Link>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-6 align-self-center" style={{ marginBottom: '-10px' }}>
                  <ul className="social-media">
                    {/* <li>
                      <Link to={getSocialLink('Facebook')} target='_blank'>
                        <FaFacebookF />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to={getSocialLink('Twitter')} target='_blank'>
                        <FaTwitter />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to={getSocialLink('Instagram')} target='_blank'>
                        <FaInstagram />
                      </Link>
                    </li> */}
                    <li>
                      <Link to={getSocialLink('Linkedin')} target='_blank'>
                        <FaLinkedin />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 text-lg-end">
                  <p>&copy; {(new Date().getFullYear())} Igniters hub All Right Reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
