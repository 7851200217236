import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../Images/1.png'
import axios from 'axios';
import { IMAGE_URL, apiBaseUrl } from '../config/config';

const NavBar = ({ header }) => {
  const [active, setActive] = useState(false);
  // const [searchShow, setSearchShow] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [logoData, setLogoData] = useState(null);
  const menuActive = () => {
    setActive(!active);
  };
  // const searchActive = () => {
  //   setSearchShow(!searchShow);
  // };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchLogoData();
  }, [])

  const fetchLogoData = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "logos?populate=*");
      if (response.data.data.length > 0) {
        const logoUrl = response.data.data[0].attributes;
        setLogoData(logoUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Control sidebar navigation
  let items = document.querySelectorAll('.menu-item-has-children > a');
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector('.sub-menu')
          .classList.toggle('active');
        this.classList.toggle('open');
      };
    }
  }

  return (
    <>

      {/* <div
        className={searchShow ? 'td-search-popup active' : 'td-search-popup '}
        id="td-search-popup"
      >
        <form action="/" className="search-form">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="submit" className="submit-btn">
            <FaSearch />
          </button>
        </form>
      </div> */}
      {/* search popup end*/}
      {/* <div
        onClick={searchActive}
        className={searchShow ? 'body-overlay active' : 'body-overlay'}
        id="body-overlay"
      ></div> */}
      {/* navbar start */}
      <nav className="navbar navbar-area navbar-area_1 navbar-expand-lg" style={{ marginTop: "-10px" }}>
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? 'menu toggle-btn d-block d-lg-none open'
                  : 'menu toggle-btn d-block d-lg-none'
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo">
            <a href="/">
              {logoData ? (
                <img style={{
                  maxWidth: "240px",
                  maxHeight: "auto",
                  width: "100%",
                  height: "auto"
                }} src={logoData?.MobileLogo?.data?.attributes?.url ? IMAGE_URL + logoData?.MobileLogo?.data?.attributes?.url : ""} alt="Logo" />
              ) : null}
            </a>
          </div>
          {/* <div className="nav-right-part nav-right-part-mobile">
            <span className="search-bar-btn" onClick={searchActive}>
              <FaSearch />
            </span>
          </div> */}
          <div
            className={
              active
                ? 'collapse navbar-collapse sopen'
                : 'collapse navbar-collapse'
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-end">
              <li className="">
                <Link to="/" style={{ fontSize: "16px" }}>Home</Link>
                {/* <ul className="sub-menu">
                  <li>
                    <Link to="/">IT / Softwer Agency</Link>
                  </li>
                  <li>
                    <Link to="/index-2">SaaS App Landing</Link>
                  </li>
                  <li>
                    <Link to="/index-3">Payments Solution</Link>
                  </li>
                  <li>
                    <Link to="/index-4">Software Company</Link>
                  </li>
                  <li>
                    <Link to="/index-5">Artificial Intelligence</Link>
                  </li>
                  <li>
                    <Link to="/index-6">NFT Service</Link>
                  </li>
                  <li>
                    <Link to="/index-7">Cyber Security</Link>
                  </li>
                  <li>
                    <Link to="/index-8">Hardware Service</Link>
                  </li>
                  <li>
                    <Link to="/index-9">ICO Landing</Link>
                  </li>
                  <li>
                    <Link to="/index-10">Personal Portfolio</Link>
                  </li>
                  <li>
                    <Link to="/index-11">SEO Service</Link>
                  </li>
                </ul> */}
              </li>
              <li >
                <Link to="/service" style={{ fontSize: "16px" }}>Services</Link>
              </li>
              <li>
                <Link to="/project" style={{ fontSize: "16px" }}>Projects</Link>
              </li>
              <li>
                <Link to="/team" style={{ fontSize: "16px" }}>Team</Link>
              </li>
              <li >
                <Link to="/career" style={{ fontSize: "16px" }}>Career</Link>
              </li>
              <li >
                <Link to="/blog" style={{ fontSize: "16px" }}>Blog</Link>
              </li>
              <li>
                <Link to="/about" style={{ fontSize: "16px" }}>About Us</Link>
              </li>
              <li>
                <Link to="/contact" style={{ fontSize: "16px" }}>Contact Us</Link>
              </li>
            </ul>
          </div>
          {/* <div className="nav-right-part nav-right-part-desktop align-self-center">
            <a className="navbar-phone" href="tel:">
              <span className="icon">
                <img src={logo} alt="img" />
              </span>
              <span>Need help?</span>
              <h5>{header && header.Phone}</h5>
            </a>
          </div> */}
        </div>
      </nav>
      {/* navbar end */}
    </>
  );
};

export default NavBar;
