import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import { Carousel } from "react-bootstrap";
import { IMAGE_URL, apiBaseUrl } from "../config/config";

const BannerOne = () => {
  const [data, setData] = useState([]);
  const [playingVideos, setPlayingVideos] = useState({});
  const [activeIndex, setActiveIndex] = useState(0); // To track the active slide index

  const fetchData = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "home-about-contents?populate=*");
      setData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Automatically play video when slide with video becomes active
  useEffect(() => {
    if (data.length > 0 && data[activeIndex]?.attributes?.VideoTestimony) {
      handlePlayVideo(activeIndex);
    }
    return () => {
      setPlayingVideos({});
    };
  }, [activeIndex, data]);


  const handlePlayVideo = (index) => {
    setPlayingVideos((prev) => {
      const newPlayingVideos = {};
      Object.keys(prev).forEach(key => {
        newPlayingVideos[key] = false;
      });
      newPlayingVideos[index] = true;
      return newPlayingVideos;
    });
  };

  const renderVideoElement = (videoUrl, index, thumbnailUrl) => {
    const videoId = videoUrl.split("v=")[1];
    const isPlaying = playingVideos[index];

    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "340px",
          overflow: "hidden",
        }}
      >
        {isPlaying ? (
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
            style={{ borderRadius: "10px", objectFit: "cover" }}
          />
        ) : (
          <div
            style={{
              position: "relative",
              cursor: "pointer",
              width: "100%",
              height: "100%",
            }}
            onClick={() => handlePlayVideo(index)}
          >
            <img
              src={thumbnailUrl ? thumbnailUrl : "assets/img/video-thumbnail.png"}
              alt="video-thumbnail"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "50px",
                color: "#fff",
              }}
            >
              ▶
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className="banner-area bg-relative banner-area-1 pd-bottom-100 bg-cover"
        style={{
          backgroundImage: 'url("./assets/img/banner/0.png")',
          marginTop: "-150px",
        }}
      >
        <img
          className="animate-img-1 top_image_bounce"
          src="assets/img/banner/2.png"
          alt="img"
        />
        <img
          className="animate-img-2 left_image_bounce"
          src="assets/img/banner/5.svg"
          alt="img"
        />
        <div className="container" style={{ marginTop: 20 }}>
          <div className="row">
            <div className="col-lg-12">
              <Carousel
                controls={false}
                indicators={true}
                interval={null}
                activeIndex={activeIndex} // Controlled carousel
                onSelect={(selectedIndex) => setActiveIndex(selectedIndex)} // Update active index
              >
                {data.map((item, index) => (
                  <Carousel.Item key={index}>
                    <div
                      className="row"
                      style={{
                        minHeight: "520px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="col-lg-6 align-self-center">
                        <div className="pe-xl-5">
                          <h6
                            className="subtitle"
                            data-aos="fade-right"
                            data-aos-delay="100"
                            data-aos-duration="1500"
                          >
                            {item.attributes.Title}
                          </h6>
                          <h2
                            className="title"
                            style={{ fontSize: "30px", marginTop: "10px" }}
                            data-aos="fade-right"
                            data-aos-delay="200"
                            data-aos-duration="1500"
                          >
                            {item.attributes.Subtitle}
                          </h2>
                          <p
                            className="content pe-xl-5"
                            data-aos="fade-right"
                            data-aos-delay="250"
                            data-aos-duration="1500"
                            style={{ textAlign: "justify" }}
                          >
                            {item.attributes.Description}
                          </p>
                          <div className="button-with-icon">
                            <Link
                              className="btn btn-border-base"
                              data-aos="fade-right"
                              data-aos-delay="300"
                              data-aos-duration="1500"
                              to="/about"
                              style={{ position: "relative", zIndex: 10 }}
                              onClick={(e) => e.stopPropagation()}
                            >
                              Discover More <FaPlus />
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-4 col-lg-5 col-md-8 offset-xl-2 offset-lg-1">
                        <div
                          className="mt-5 mt-lg-0 ms-4 ms-lg-0"
                          data-aos="fade-left"
                          data-aos-delay="100"
                          data-aos-duration="1500"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "auto", // Ensure height is auto for images
                            overflow: "hidden",
                            margin: "0 auto", // Center the container
                          }}
                        >
                          {index === 0 ? (
                            <img
                              className="main-img"
                              src={IMAGE_URL + item.attributes?.Image?.data?.attributes?.url}
                              alt="img"
                              style={{
                                width: "100%",
                                height: "auto", // Set auto height for images to ensure correct sizing on mobile
                                objectFit: "contain", // Ensures the image doesn't crop on mobile
                                borderRadius: "10px",
                              }}
                            />
                          ) : (
                            renderVideoElement(
                              item.attributes.VideoTestimony,
                              index,
                              IMAGE_URL + item.attributes?.Image?.data?.attributes?.url
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        @media (max-width: 767px) {
          .banner-area {
            margin-top: 0;
            padding-bottom: 50px;
          }

          .banner-area .row {
            flex-direction: column;
            text-align: center;
          }

          .banner-area .col-lg-6{
            width: 89%;
            max-width: 100%;
          }
          .banner-area .col-xl-4 {
            width: 89%;
            max-width: 100%;
            margin-left:-40px
          }

          .main-img {
            max-height: none;
            height: auto;
          }

          iframe {
            height: 300px !important;
          }

          .button-with-icon {
            justify-content: center;
          }

          .subtitle {
            font-size: 18px;
          }

          .title {
            font-size: 24px;
          }

          .content {
            font-size: 14px;
            text-align: justify;
          }
        }
          .content {
            hyphens: none;
            word-break: normal;
            overflow-wrap: break-word;
            white-space: normal;
        }
      `}</style>
    </>
  );
};

export default BannerOne;
