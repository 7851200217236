import React, { useState, useEffect } from "react";
import AboutAreaOne from "../components/AboutAreaOne";
import BannerOne from "../components/BannerOne";
import BlogAreaOne from "../components/BlogAreaOne";
import CaseStudyAreaOne from "../components/CaseStudyAreaOne";
import ContactAreaOne from "../components/ContactAreaOne";
import CounterAreaOne from "../components/CounterAreaOne";
import FaqAreaOne from "../components/FaqAreaOne";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import PricingAreaOne from "../components/PricingAreaOne";
import ServiceAreaOne from "../components/ServiceAreaOne";
import TeamAreaOne from "../components/TeamAreaOne";
import WorkProcessOne from "../components/WorkProcessOne";
import axios from "axios";
import { apiBaseUrl } from "../config/config";
import { Link, useLocation } from "react-router-dom";
import Helmet from "react-helmet";

const HomeOne = () => {
  const [header, setHeader] = useState([]);
  const [seo, setSeo] = useState({
   
  });

  const location = useLocation();

  const fetchHeader = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "headers?populate=*");
      setHeader(response.data.data[0].attributes);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchGlobalSEO = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "seos");
      const seoData = response.data.data;

      const currentPageSeo = seoData.find(
        (item) => item.attributes.Pages === getPageName()
      );
      const globalSeo = seoData.find(
        (item) => item.attributes.Pages === "Global"
      );

      if (currentPageSeo) {
        setSeo({
          title: currentPageSeo.attributes.Title || "Default Title",
          description: currentPageSeo.attributes.Desc || "Default Description",
        });
      } else if (globalSeo) {
        setSeo({
          title: globalSeo.attributes.Title || "Default Title",
          description: globalSeo.attributes.Desc || "Default Description",
        });
      } else {
        setSeo({
          title: "Default Title",
          description: "Default Description",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPageName = () => {
    if (location.pathname === "/") return "Home";
  };

  useEffect(() => {
    fetchHeader();
    fetchGlobalSEO();
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      {/* Navigation Bar */}
      <NavBar header={header} />

      {/* Banner One */}
      <BannerOne />

      {/* Case Study Area One */}
      <CaseStudyAreaOne />

      {/* Service Area One */}
      <ServiceAreaOne />

      {/* About Area One */}
      {/* <AboutAreaOne /> */}

      {/* FAQ Area One */}
      {/* <FaqAreaOne /> */}

      {/* Team Area One */}
      {/* <TeamAreaOne /> */}

      {/* Counter Area One */}
      <CounterAreaOne />

      {/* Contact Area One */}
      <ContactAreaOne />

      {/* Work Process One */}
      {/* <WorkProcessOne /> */}

      {/* Pricing Area One */}
      {/* <PricingAreaOne /> */}

      {/* Blog Area One */}
      <BlogAreaOne />

      {/* Footer One */}
      <FooterOne header={header} />
    </>
  );
};

export default HomeOne;
