import React, { useEffect, useRef, useState } from 'react';
import SingleBrandV1 from './SingleBrandV1';

const WorkProgressV3 = ({ urls }) => {
    const scrollContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isManualScrolling, setIsManualScrolling] = useState(false);
    const scrollSpeed = 2; // Increased scroll speed

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (!scrollContainer) return;

        const scroll = () => {
            if (isManualScrolling) return;

            const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

            if (scrollPosition >= maxScrollLeft) {
                // Reset to start when reaching the end
                setScrollPosition(0);
                scrollContainer.scrollLeft = 0;
            } else {
                setScrollPosition(prev => prev + scrollSpeed);
                scrollContainer.scrollLeft = scrollPosition + scrollSpeed;
            }
        };

        const intervalId = setInterval(scroll, 20);

        return () => clearInterval(intervalId);
    }, [scrollPosition, isManualScrolling]);

    const containerStyle = {
        backgroundImage: "url(/images/background/progress.png)",
        color: '#fff',
        paddingRight: '25px',
        paddingLeft: '25px',
        borderRadius: '5px',
        position: 'relative',
    };

    const scrollContainerStyle = {
        display: 'flex',
        overflowX: 'scroll',
        whiteSpace: 'nowrap',
        padding: '30px 10px',
        height: '160px',
        cursor: 'grab',
        userSelect: 'none',
    };

    const slideStyle = {
        flex: '0 0 auto',
        marginRight: '20px',
    };

    const scrollbarStyles = {
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
    };

    const handleMouseDown = (e) => {
        setIsManualScrolling(true);
        const scrollContainer = scrollContainerRef.current;
        scrollContainer.style.cursor = 'grabbing';
    };

    const handleMouseMove = (e) => {
        if (!isManualScrolling) return;
        const scrollContainer = scrollContainerRef.current;
        const newScrollLeft = scrollContainer.scrollLeft;
        setScrollPosition(newScrollLeft);
    };

    const handleMouseUpOrLeave = () => {
        setIsManualScrolling(false);
        const scrollContainer = scrollContainerRef.current;
        scrollContainer.style.cursor = 'grab';
    };

    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            setScrollPosition(scrollContainer.scrollLeft);
        }
    };

    return (
        <section className="work-progress-three" style={containerStyle}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section-title text-center">
                            <h6 className="sub-title">Our Clients</h6>
                            <h2 className="title">
                                Our<span> Awesome </span> Clients
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="container">
                <div className="logo-carousel-wrap style-two bg-white py-100 px-25 br-5">
                    <div
                        className="horizontal-scroll"
                        style={{ ...scrollContainerStyle, ...scrollbarStyles }}
                        ref={scrollContainerRef}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUpOrLeave}
                        onMouseLeave={handleMouseUpOrLeave}
                        onScroll={handleScroll}
                    >
                        {urls.map((url, index) => (
                            <div className="slide" style={slideStyle} key={index}>
                                <SingleBrandV1 url={url} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WorkProgressV3;