import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { apiBaseUrl } from '../config/config';
import BlogDetailsArea from "../components/BlogDetailsArea";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

const BlogDetails = () => {

  const { id } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [seo, setSeo] = useState({
    title: "Default Title",
    description: "Default Description",
  });

  const location = useLocation();  

 
  const fetchGlobalSEO = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "seos");
      const seoData = response.data.data;

     
      const currentPageSeo = seoData.find(
        (item) => item.attributes.Pages === getPageName()
      );
 
      const globalSeo = seoData.find(
        (item) => item.attributes.Pages === "Global"
      );
      if (currentPageSeo) {
        setSeo({
          title: currentPageSeo.attributes.Title || "Default Title",
          description: currentPageSeo.attributes.Desc || "Default Description",
        });
      } else if (globalSeo) {
  
        setSeo({
          title: globalSeo.attributes.Title || "Default Title",
          description: globalSeo.attributes.Desc || "Default Description",
        });
      } else {

        setSeo({
          title: "Default Title",
          description: "Default Description",
        });
      }
    } catch (error) {
      console.error("SEO fetch error:", error);
    }
  };

 
  const getPageName = () => {
    if (location.pathname.includes("/blog")) return "Blogs";
   
  };

  const fetchBlogData = async () => {
    try {
      const response = await axios.get(apiBaseUrl + `blog-page-contents/${id}?populate=*`);
      setBlogData(response.data.data.attributes);
    } catch (error) {
      console.error(error);
    }
  };

  const [header, setHeader] = useState([]);

  const fetchHeader = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl + "headers"
      );

      setHeader(response.data.data[0].attributes);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchHeader();
    fetchGlobalSEO()
    fetchBlogData();
  }, [id]);

  return (
    <>
    <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      {/* Navigation Bar */}
      <NavBar header={header} />

      {/* Navigation Bar */}
      <Breadcrumb title={"Blog Details"} />

      {/* Blog Details Area */}
      {blogData && <BlogDetailsArea blog={blogData} BlogId={id} />}

      {/* Footer One */}
      <FooterOne header={header} />
    </>
  );
};

export default BlogDetails;