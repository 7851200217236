import React, { useState, useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl } from '../config/config';

const ServiceAreaOne = (props) => {
  const { featureSpace } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "services?populate=*");
      setData(response.data.data);
      console.log("data::", response);
    } catch (error) {
      console.error(error);
    }
  };

  const truncateDescription = (text) => {
    const lines = text.split('\n');
    if (lines.length > 4) {
      return lines.slice(0, 4).join('\n') + '...'; // Show only 4 lines and append ellipsis
    }
    return text;
  };

  // Inline styles
  // const containerStyle = {
  //   overflowX: 'auto',
  //   whiteSpace: 'nowrap'
  // };

  // const wrapperStyle = {
  //   display: 'inline-flex',
  //   flexWrap: 'nowrap',
  //   gap: '20px' // Add space between items
  // };

  // const serviceStyle = {
  //   display: 'inline-block',
  //   width: '300px' // Adjust based on your design
  // };

  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-relative pd-top-100'>
        <img
          className="position-bottom-left top_image_bounce image-responsive"
          src="assets/img/icon/4.png"
          alt="img"
        />
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>WHO WE ARE</h6>
            <h2 className='title'>
              We deal with the aspects of professional <span>IT Services</span>
            </h2>
          </div>
          {/* <div style={containerStyle}>
            <div style={wrapperStyle}>
              {data.map((service, index) => (
                <div className='single-service-inner text-center' key={index} style={serviceStyle}>
                  <div className="icon">
                    <i className={service.attributes.Icon2}></i>
                  </div>
                  <div className='details'>
                    <h5>
                      <Link to={`/service-details/${service.attributes.slug}`}>
                        {service.attributes.Title}
                      </Link>
                    </h5>
                    <p style={{ maxHeight: '110px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line' }}>
                      {truncateDescription(service.attributes.Description)}
                    </p>
                    <Link className='btn btn-border-base' to={`/service-details/${service.attributes.slug}`}>
                      Read More <FaPlus />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          <div className="container">
            <div className="row">
              {data.slice(0, 6).map((service, index) => (
                <div className="col-lg-4 col-md-6" key={service.id || index}>
                  <div className="single-work-process-inner">
                    <div className="thumb mb-3">
                      <img
                        src={`assets/img/icon/${index + 1 + 15}.svg`}
                        alt="Service Icon"
                      />
                    </div>
                    <div className="details">
                      <h5 className="mb-3">{service.attributes.Title || 'Service Title'}</h5>
                      <p className="content mb-3 truncated-text" style={{ maxHeight: '117px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line' }}>
                        {truncateDescription(service.attributes.ShortDescription || service.attributes.Description || 'Service Description')}

                      </p>
                      <Link className="read-more-text" to={`/service-details/${service.attributes.slug}`}>
                        Read More <FaArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaOne;
