import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import ServiceDetailsArea from "../components/ServiceDetailsArea";
import axios from 'axios';
import { apiBaseUrl } from '../config/config';


const ServiceDetails = () => {
  const { id } = useParams();
  const [header, setHeader] = useState([]);
  const [data, setData] = useState([]);

  const fetchHeader = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl + "headers?populate=*"
      );

      setHeader(response.data.data[0].attributes);
      console.log("id", response)

    } catch (error) {
      console.error(error);
    }
  };
  const fetchData = async (id) => {
    try {
      const response = await axios.get(
        apiBaseUrl + `services/${id}?populate=*`
      );
      setData(response.data.data);
      console.log("dataaaa:", response)

    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchHeader();
    fetchData(id);
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <>
      {/* Navigation Bar */}
      <NavBar header={header} />

      {/* Navigation Bar */}
      <Breadcrumb title={"Service Details"} />

      {/* Service Details Area */}
      {data && <ServiceDetailsArea header={header} service={data} serviceId={id} />}

      {/* Footer One */}
      {/* <FooterOne header={header} /> */}
    </>
  );
};

export default ServiceDetails;