import React, { useState, useEffect } from 'react';
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import axios from 'axios';
import { apiBaseUrl } from '../config/config';

const CounterAreaOne = () => {
  const [counterData, setCounterData] = useState([]);
  const fetchCounterData = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl + "home-counters"
      );

      setCounterData(response.data.data);
      console.log("Counter::", response)

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCounterData();
  }, []);
  return (
    <>
      {/* counter area start */}

      <div
        className='counter-area bg-relative bg-cover pd-top-60 pd-bottom-100'
        style={{ backgroundImage: 'url("./assets/img/bg/5.png")' }}
      >
        <div className='container pd-bottom-90'>

          <div
            className=''
            data-aos='fade-up'
            data-aos-delay='100'
            data-aos-duration='1500'
          >
            <div className='single-counter-inner'>
              <div className='thumb'>
                <div className='row justify-content-md-center' style={{ display: 'flex', justifyContent: 'space-around' }}>


                  {counterData.slice(0, 3).map(counter =>
                    <div className="col-md-3 col-sm-6" key={counter.id} style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div className="success-item" style={{ textAlign: 'center', padding: '20px' }}>
                        <span className="text-white mt-4 mb-2 " style={{ fontSize: '4em' }} >
                          <CountUp start={counter.attributes.start} end={counter.attributes.end} duration={5} />+
                        </span>
                        <p className="text-white  " style={{ fontSize: '1.5em' }}>{counter.attributes.title}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter area end */}
    </>
  );
};

export default CounterAreaOne;
