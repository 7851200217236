import React, { useState, useEffect } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import FooterOne from '../components/FooterOne';
import NavBar from '../components/NavBar';
import TeamAreaGroupThree from '../components/TeamAreaGroupThree';
import axios from 'axios';
import { apiBaseUrl } from '../config/config';

const TeamThree = () => {
  const [header, setHeader] = useState([]);

  const fetchHeader = async () => {
    try {
      const response = await axios.get(apiBaseUrl + 'headers?populate=*');
      setHeader(response.data.data[0].attributes);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchHeader();

    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Navigation Bar */}
      <NavBar header={header} />

      {/* Breadcrumb */}
      <Breadcrumb title={'Team'} />

      {/* TeamAreaGroupThree */}
      <TeamAreaGroupThree />

      {/* WorkProcessOne */}
      {/* <WorkProcessOne /> */}

      {/* Footer One */}
      <FooterOne header={header} />
    </>
  );
};

export default TeamThree;
