import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { FaCalendarAlt, FaRegUser, FaQuoteRight } from 'react-icons/fa';
import { apiBaseUrl, IMAGE_URL } from '../config/config';

const ContentRenderer = ({ content }) => {
  return (
    <div style={{ textAlign: 'justify' }}>
      {content.map((block, index) => {
        switch (block.type) {
          case 'paragraph':
            return (
              <p key={index}>
                {block.children.map((child, childIndex) => {
                  const textStyle = {
                    fontWeight: child.bold ? 'bold' : 'normal',
                    fontStyle: child.italic ? 'italic' : 'normal',
                    textDecoration: child.underline ? 'underline' : 'none',
                  };
                  return (
                    <span key={childIndex} style={textStyle}>
                      {child.text}
                    </span>
                  );
                })}
              </p>
            );
          case 'image':
            const imageUrl = block.image.url ? `${block.image.url}` : 'default-image.jpg';
            return (
              <div
                key={index}
                style={{
                  textAlign: 'center',
                  margin: '20px auto',
                  maxWidth: '100%',
                  marginTop: '80px',
                  marginBottom: '80px'
                }}
              >
                <img
                  src={imageUrl}
                  alt={block.image.alternativeText || 'Blog image'}
                  style={{
                    maxWidth: '90%',
                    height: 'auto',
                    display: 'block',
                    margin: '0 auto',
                  }}
                />
              </div>
            );
          case 'heading':
            const HeadingTag = `h${block.level}`;
            return (
              <HeadingTag key={index}>
                {block.children.map((child, childIndex) => (
                  <span key={childIndex}>{child.text}</span>
                ))}
              </HeadingTag>
            );
          case 'list':
            const ListTag = block.format === 'ordered' ? 'ol' : 'ul';
            return (
              <ListTag
                key={index}
                style={{
                  paddingLeft: '20px',
                  marginBottom: '20px',
                  lineHeight: '1.6',
                  listStylePosition: 'inside',
                }}
              >
                {block.children.map((listItem, listItemIndex) => (
                  <li key={listItemIndex}>
                    {listItem.children.map((item, itemIndex) => (
                      <span key={itemIndex}>
                        {item.text}
                      </span>
                    ))}
                  </li>
                ))}
              </ListTag>
            );
          case 'link':
            return (
              <a key={index} href={block.href} target="_blank" rel="noopener noreferrer">
                {block.children.map((child, childIndex) => (
                  <span key={childIndex}>{child.text}</span>
                ))}
              </a>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

const BlogDetailsArea = ({ blog, BlogId }) => {
  const [blogComments, setBlogComments] = useState([]);
  const [recentNews, setRecentNews] = useState([]);
  const [contactInfo, setContactInfo] = useState({
    Name: "",
    Email: "",
    Comment: ""
  });

  useEffect(() => {
    const fetchRecentNews = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}blog-page-contents?populate=*`);
        setRecentNews(response.data.data);
      } catch (error) {
        console.error('Error fetching recent news:', error);
      }
    };

    const fetchBlogComments = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}blog-comments`);
        const comments = response.data.data.filter(comment =>
          comment.attributes.BlogId === BlogId && comment.attributes.Verified
        );
        setBlogComments(comments);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchRecentNews();
    fetchBlogComments();
    window.scrollTo(0, 0);
  }, [BlogId]);

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      const currentDate = new Date().toISOString().split('T')[0];
      await axios.post(`${apiBaseUrl}blog-comments`, {
        data: {
          ...contactInfo,
          BlogId: BlogId,
          Date: currentDate
        },
      });

      toast.success("Thanks, Your Comment Will Be Posted After Review");
      setContactInfo({
        Name: "",
        Email: "",
        Comment: ""
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <div className="blog-area blog-area_details pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner">
                  <div className="thumb">
                    <img
                      src={IMAGE_URL + (blog.SingleBlog?.data.attributes.url || 'default-image.jpg')}
                      alt="Blog featured image"
                    />
                  </div>
                  <div className="details">
                    <ul className="blog-meta">
                      <li><FaRegUser /> By {blog.Author || 'Author'}</li>
                      <li><FaCalendarAlt /> {blog.Date || 'Date not available'}</li>
                    </ul>
                    <h3>{blog.Title || 'Title not available'}</h3>

                    <ContentRenderer content={blog.BlogDescription || []} />

                    {blog.QuoteText && (
                      <blockquote>
                        <FaQuoteRight />
                        <p>{blog.QuoteText}</p>
                        <p className="name">{blog.QuoteAuthor || 'Author'}</p>
                      </blockquote>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="td-sidebar">
                <div className="widget widget-recent-post">
                  <h4 className="widget-title">Recent News</h4>
                  <ul>
                    {recentNews.map((newsItem) => {
                      const { Title, Date: newsDate, slug } = newsItem.attributes;
                      const formattedDate = new Date(newsDate).toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'short'
                      }).toUpperCase();

                      return (
                        <li key={newsItem.id}>
                          <div className="media">
                            <div className="media-left">
                              <img
                                src={IMAGE_URL + newsItem.attributes.SingleBlog?.data?.attributes?.url}
                                alt="Recent news thumbnail"
                                style={{ width: "100px", height: "70px" }}
                              />
                            </div>
                            <div className="media-body align-self-center">
                              <h6 className="title">
                                <Link to={`/blog-details/${slug}`}>{Title}</Link>
                              </h6>
                              <div className="post-info">
                                <FaCalendarAlt />
                                <span>{formattedDate}</span>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailsArea;