import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import { apiBaseUrl } from '../config/config';


const ContactMain = ({ contactData }) => {
  const form = useRef();
  const mapRef = useRef(null);

  const [contactInfo, setContactInfo] = useState({
    name: "",
    Email: "",
    Message: "",
  });

  useEffect(() => {
    // Check if the hash in the URL corresponds to the map section
    if (window.location.hash === '#map-section') {
      // Scroll into view
      mapRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const sendEmail = async (e) => {

    e.preventDefault();
    try {
      await axios.post(apiBaseUrl + "contacts", {
        data: {
          name: contactInfo.name,
          Email: contactInfo.Email,
          Message: contactInfo.Message,
          FromPage: "ContactUs"
        },
      });
      emailjs
        .sendForm(
          'service_z2fkdpb', //YOUR_SERVICE_ID
          'template_y4pd6bs', //YOUR_TEMPLATE_ID
          form.current,
          'f23lqZQNdoXVxUhvs', //YOUR_PUBLIC_KEY
        )
        .then(
          (result) => {
            if (result.text === 'OK') {
              toast.success('Message Sent Successfully!');
              form.current[0].value = '';
              form.current[1].value = '';
              form.current[3].value = '';
            }
          },
          (error) => {
            if (error.text !== 'OK') {
              toast.success('Message Not Sent!');
            }
          },
        );
      setContactInfo({
        name: "",
        Email: "",
        Phone: "",
        Message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      {/* ================= Contact Main start =================*/}
      <Toaster position="bottom-center" reverseOrder={false} />
      <>
        {/* contact area start */}
        <div className="contact-area pd-top-120 pd-bottom-120">
          <div className="container">
            <div className="contact-page-inner bg-gray">
              <div className="section-title mb-4 pb-2">
                <h2 className="title">Get In Touch </h2>
                <p className="content mb-0">
                  Do you have anything in your mind to tell us?
                  {/* <br /> */}
                  &nbsp;  &nbsp; Please don't hesitate to get in touch to us via our contact form.
                </p>
              </div>
              <form ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="single-input-inner">
                      <input
                        id="full-name"
                        name="name"
                        type="text"
                        placeholder="Enter Your Name."
                        autoComplete="off"
                        required
                        maxLength="50"
                        value={contactInfo.name}
                        onChange={(e) => {
                          const value = e.target.value;
                          const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
                          setContactInfo({
                            ...contactInfo,
                            name: filteredValue,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-input-inner">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter Your Email."
                        autoComplete='off' required value={contactInfo.Email}
                        onChange={(e) => {
                          setContactInfo({
                            ...contactInfo,
                            Email: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    {/* <div className="single-input-inner">
                      <input
                        id="subject"
                        name="subject"
                        type="text"
                        placeholder="Enter Your Subject."
                        required
                      />
                    </div> */}
                  </div>
                  <div className="col-12">
                    <div className="single-input-inner">
                      <textarea
                        name="comments"
                        id="comments"
                        cols="1"
                        rows="5"
                        placeholder="Enter Your Message ..."
                        maxLength="500"
                        autoComplete='off' required value={contactInfo.Message}
                        onChange={(e) => {
                          setContactInfo({
                            ...contactInfo,
                            Message: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-base border-radius-5"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* contact area end */}
        {/* contact list start */}
        <div className="contact-page-list">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="media single-contact-list">
                  <div className="media-left">
                    <img src="assets/img/icon/13.svg" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5>Contacts us</h5>
                    <h6>{contactData && contactData.Phone1}</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="media single-contact-list">
                  <div className="media-left">
                    <img src="assets/img/icon/14.svg" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5>Your Email</h5>
                    <h6>{contactData && contactData.Mail}</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="media single-contact-list">
                  <div className="media-left">
                    <img src="assets/img/icon/15.svg" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5>Location</h5>
                    <h6 style={{ fontSize: "12.7px", fontWeight: "900" }}>{contactData && contactData.Address}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* contact list start */}
        {/* map start */}
        <div id="map-section" ref={mapRef} >
          <div className="contact-g-map">
            <iframe
              src={contactData && contactData.GoogleMap}
            />
          </div>
        </div>

        <br></br>
        <br></br>
        {/* map end */}
      </>

      {/* ================= Contact Main End =================*/}
    </>
  );
};

export default ContactMain;
