import React, { useState, useEffect, useRef } from 'react';
import { FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { IMAGE_URL, apiBaseUrl } from '../config/config';

const CaseStudyArea = ({ portfolioId }) => {
  const [data, setData] = useState(null);
  const [selectedPortfolio, setSelectedPortfolio] = useState(portfolioId);
  const [sidebarStyle, setSidebarStyle] = useState({});
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);

  const fetchData = async (id) => {
    try {
      const response = await axios.get(`${apiBaseUrl}galleries/${id}?populate=*`);
      setData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedPortfolio) {
      fetchData(selectedPortfolio);
    }
  }, [selectedPortfolio]);

  useEffect(() => {
    const handleScroll = () => {
      if (!sidebarRef.current || !contentRef.current) return;

      const footer = document.querySelector('footer');
      const footerTop = footer ? footer.getBoundingClientRect().top : window.innerHeight;
      const sidebarRect = sidebarRef.current.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();
      const scrollY = window.scrollY;
      const buffer = 50;

      if (window.innerWidth <= 992) {
        setSidebarStyle({});
        return;
      }

      const contentBottom = contentRect.bottom;
      const sidebarBottom = sidebarRect.bottom;

      if (scrollY < 320) {
        setSidebarStyle({
          position: 'static',
          width: '100%',
          opacity: 1,
          pointerEvents: 'auto',
        });
      } else if (contentBottom > sidebarBottom + buffer) {
        setSidebarStyle({
          position: 'fixed',
          top: '20px',
          width: sidebarRect.width + 'px',
          opacity: 1,
          pointerEvents: 'auto',
        });
      } else {
        setSidebarStyle({
          position: 'fixed',
          top: '10px',
          width: sidebarRect.width + 'px',
          opacity: 0,
          pointerEvents: 'auto',
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const handlePortfolioClick = (id) => {
    setSelectedPortfolio(id);
  };


  const ContentRenderer = ({ content }) => {
    return (
      <div style={{ textAlign: 'justify' }}>
        {content.map((block, index) => {
          switch (block.type) {
            case 'paragraph':
              return (
                <p key={index}>
                  {block.children.map((child, childIndex) => {
                    const textStyle = {
                      fontWeight: child.bold ? 'bold' : 'normal',
                      fontStyle: child.italic ? 'italic' : 'normal',
                      textDecoration: child.underline ? 'underline' : 'none',
                    };
                    return (
                      <span key={childIndex} style={textStyle}>
                        {child.text}
                      </span>
                    );
                  })}
                </p>
              );
            case 'image':
              const imageUrl = block.image.url ? `${block.image.url}` : 'default-image.jpg'; // Using the full URL
              return (
                <div
                  key={index}
                  style={{
                    textAlign: 'center',
                    margin: '20px auto',
                    maxWidth: '100%',
                    marginTop: '80px',
                    marginBottom: '80px'
                  }}
                >
                  <img
                    src={imageUrl}
                    alt={block.image.alternativeText || 'Image'}
                    style={{
                      maxWidth: '90%',
                      height: 'auto',
                      display: 'block',
                      margin: '0 auto',
                    }}
                  />
                </div>
              );

            case 'heading':
              const HeadingTag = `h${block.level}`;
              return (
                <HeadingTag key={index}>
                  {block.children.map((child, childIndex) => (
                    <span key={childIndex}>{child.text}</span>
                  ))}
                </HeadingTag>
              );
            case 'list':
              const ListTag = block.format === 'ordered' ? 'ol' : 'ul';
              return (
                <ListTag
                  key={index}
                  style={{
                    paddingLeft: '20px',
                    marginBottom: '20px',
                    lineHeight: '1.6',
                    listStylePosition: 'inside',
                  }}
                >
                  {block.children.map((listItem, listItemIndex) => (
                    <li key={listItemIndex}>
                      {listItem.children.map((item, itemIndex) => (
                        <span key={itemIndex}>
                          {item.text}
                        </span>
                      ))}
                    </li>
                  ))}
                </ListTag>
              );
            case 'link':
              return (
                <a key={index} href={block.href} target="_blank" rel="noopener noreferrer">
                  {block.children.map((child, childIndex) => (
                    <span key={childIndex}>{child.text}</span>
                  ))}
                </a>
              );
            default:
              return null;
          }
        })}
      </div>
    );
  };


  return (
    <div className='service-area pd-top-120 pd-bottom-90'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 col-12'>
            <div
              ref={sidebarRef}
              className='td-sidebar service-sidebar'
              style={{
                ...sidebarStyle,
                transition: 'opacity 0.3s ease-out, top 0.3s ease-out',
              }}
            >
              <div className='widget widget_info'>
                <h5 className='widget-title'>
                  <FaArrowRight /> Project Info
                </h5>
                <div className='widget-info-inner'>
                  {data ? (
                    <div>
                      <h6>Customer Profile</h6>
                      <p>{data.attributes.CustomerProfile}</p>
                      <h6>Category</h6>
                      <p>{data.attributes.Category}</p>
                      <h6>Project Year</h6>
                      <p>{data.attributes.ProjectYear}</p>
                      <h6>Tools & Technologies</h6>
                      <p>{data.attributes.ToolsandTechnologies}</p>
                    </div>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-8' ref={contentRef}>
            <div className='blog-details-page-content'>
              <div className='single-blog-inner mb-0'>
                {/* <div className='thumb' style={{ paddingTop: '5px' }}>
                  {data && data.attributes.Image && (
                    <img
                      src={IMAGE_URL + data?.attributes?.Image?.data?.attributes?.url}
                      alt="Gallery"
                      style={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: '530px',
                        objectFit: 'cover',
                        borderRadius: '5px',
                      }}
                    />
                  )}
                </div> */}
                <div className='details'>
                  <h4>{data ? data.attributes.Title : "Loading..."}</h4>
                  {data && data.attributes.Desc ? (
                    <ContentRenderer content={data.attributes.Desc || []} />
                  ) : (
                    <p>Loading description...</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyArea;