import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IMAGE_URL, apiBaseUrl } from '../config/config';
import { Link } from 'react-router-dom';
import emailjs from "@emailjs/browser";
import { toast, Toaster } from 'react-hot-toast';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const ContactAreaOne = () => {
  const [contactInfo, setContactInfo] = useState({
    name: "",
    Email: "",
    Phone: "",
    Message: "",
    ServiceRequired: ""
  });

  const handleForm = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    try {
      // Submit the contact info to your backend
      await axios.post(apiBaseUrl + "contacts", {
        data: {
          name: contactInfo.name,
          Email: contactInfo.Email,
          Phone: contactInfo.Phone,
          Message: contactInfo.Message,
          ServiceRequired: contactInfo.ServiceRequired,
          FromPage: "Home"
        },
      });

      // Send email using EmailJS
      emailjs.sendForm('service_z2fkdpb', 'template_y4pd6bs', e.target, 'f23lqZQNdoXVxUhvs')
        .then((result) => {
          console.log(result.text);
          toast.success("Thanks For Contacting Us");
        }, (error) => {
          console.error("Error sending email:", error);
          toast.error("An error occurred while sending email. Please try again later.");
        });

      // Reset the form fields after a successful submission
      setContactInfo({
        name: "",
        Email: "",
        Phone: "",
        Message: "",
        ServiceRequired: ""
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const [image2, setImage2] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [service, setService] = useState([]);

  const serviceRequired = async () => {
    const response = await axios.get(apiBaseUrl + "form-dropdowns");
    setService(response.data.data);
  };

  const fetchAboutData = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "home-abouts?populate=*");
      setImage2(response.data.data[0].attributes.Image2.data.attributes.url);
      setTitle(response.data.data[0].attributes.Title);
      setSubtitle(response.data.data[0].attributes.SubTitle);
      setDescription(response.data.data[0].attributes.Description2);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAboutData();
    serviceRequired();
  }, []);

  return (
    <>
      {/* ========================= contact Area One start =========================*/}
      <Toaster position="bottom-center" reverseOrder={false} />
      <form className="contact-form" name="contact-form" onSubmit={handleForm}>
        <div className="contact-area">
          <div className="container"
            style={{ marginBottom: 200 }}>
            <div className="contact-inner-1">
              <img
                className="top_image_bounce animate-img-1"
                src="assets/img/banner/2.png"
                alt="img"
              />
              <img
                className="top_image_bounce animate-img-2"
                src="assets/img/about/6.png"
                alt="img"
              />
              <div className="row">
                <div
                  className="col-lg-8"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  <img src={IMAGE_URL + image2} alt="About" style={{ height: 850, width: 764 }} />
                </div>
                <div
                  className="col-lg-4 wow animated fadeInRight"
                  data-aos="fade-left"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  <div className="section-title mb-0">
                    <h6 className="sub-title">{title}</h6>
                    <h2 className="title">
                      {subtitle}
                    </h2>
                    <p className="content">
                      {description}
                    </p>
                    <div className="mt-4">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="single-input-inner style-border">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder="Name"
                              autoComplete="off"
                              required
                              maxLength="50"
                              value={contactInfo.name}
                              onChange={(e) => {
                                const value = e.target.value;
                                const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
                                setContactInfo({
                                  ...contactInfo,
                                  name: filteredValue,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="single-input-inner style-border">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Email"
                              autoComplete="off"
                              required
                              value={contactInfo.Email}
                              onChange={(e) => {
                                setContactInfo({
                                  ...contactInfo,
                                  Email: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="single-input-inner style-border">
                            <input
                              type="text"
                              id="phone"
                              name="phone"
                              className="form-control"
                              placeholder="Phone Number"
                              autoComplete="off"
                              required
                              pattern="\d{10}"
                              title="Phone number should be of 10 digits"
                              value={contactInfo.Phone}
                              maxLength="10"
                              onChange={(e) => {
                                const value = e.target.value;
                                // Only allow numeric characters (0-9)
                                if (/^\d*$/.test(value)) {
                                  setContactInfo({
                                    ...contactInfo,
                                    Phone: value,
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="single-input-inner style-border">

                            <FormControl fullWidth>
                              <InputLabel id="service-label" style={{ color: '#aaa' }}>Select Service</InputLabel>
                              <Select
                                labelId="service-label"
                                id="service"
                                value={contactInfo.ServiceRequired}
                                onChange={(e) => {
                                  setContactInfo({
                                    ...contactInfo,
                                    ServiceRequired: e.target.value,
                                  });
                                }}
                                label="Service Required"
                                sx={{
                                  height: '50px', // Match height with other input fields
                                  borderRadius: '5px', // Ensure consistent border radius
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#ddd', // Same border color as other inputs
                                  },
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#ccc', // Lighter border on hover (same as input fields)
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#000', // Match border color when focused
                                  },
                                }}
                                required
                              >
                                {/* Default option */}
                                <MenuItem value="" disabled>
                                  Select Service
                                </MenuItem>

                                {/* Options from API */}
                                {service.map((data, index) => (
                                  <MenuItem key={index} value={data.attributes.ServiceRequired}>
                                    {data.attributes.ServiceRequired}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="single-input-inner style-border">
                            <textarea
                              name="message"
                              id="comments"
                              className="form-control"
                              rows="4"
                              placeholder="Let us know what you need."
                              autoComplete="off"
                              required
                              maxLength="500"
                              value={contactInfo.Message}
                              onChange={(e) => {
                                setContactInfo({
                                  ...contactInfo,
                                  Message: e.target.value,
                                });
                              }}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-black mt-0 w-100 border-radius-5"
                            type="submit"
                          >
                            Submit now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/*========================= contact-inner One end =========================*/}
    </>
  );
};

export default ContactAreaOne;
