import React, { useState, useEffect } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import FooterOne from '../components/FooterOne';
import NavBar from '../components/NavBar';
import ProjectAreaOne from '../components/ProjectAreaOne';
import TeamAreaOne from '../components/TeamAreaOne';
import CounterAreaOne from '../components/CounterAreaOne';
import ContactAreaOne from '../components/ContactAreaOne';
import WorkProgressV3 from '../components/WorkProgressV3';
import TestimonialTwo from '../components/TestimonialTwo';

import axios from 'axios';
import { apiBaseUrl } from '../config/config';

const ProjectTwo = () => {

  const [header, setHeader] = useState([]);
  const [testimonialData, setTestimonialData] = useState([]);


  const fetchHeader = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl + "headers?populate=*"
      );
      console.log("qqqq", response)
      setHeader(response.data.data[0].attributes);

    } catch (error) {
      console.error(error);
    }
  };

  const fetchTestimonialData = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl + "testimonial-pages?populate=*"

      );
      const urls = response.data.data[0].attributes.clients.data.map(client => client.attributes.url);
      setTestimonialData(urls);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchHeader();
    fetchTestimonialData();

  }, []);
  return (
    <>
      {/* Navigation Bar */}
      <NavBar header={header} />

      {/* Breadcrumb */}
      <Breadcrumb title={'Projects'} />

      {/* ProjectAreaOne */}
      <ProjectAreaOne />
      <br />
      <br />
      <br />

      <WorkProgressV3 urls={testimonialData} />

      <TestimonialTwo />
      <br />
      <br />
      <br />
      <br />

      {/* Footer One */}
      <FooterOne header={header} />
    </>
  );
};

export default ProjectTwo;
