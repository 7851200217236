import React, { useState, useEffect } from 'react';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { apiBaseUrl, IMAGE_URL } from '../config/config';
import { FaPlus } from 'react-icons/fa';


const CaseStudyAreaOne = () => {

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl + "galleries?populate=*"
      );

      setData(response.data.data);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/*================== case-study area start ==================*/}
      <div className="case-study-area bg-relative pd-top-110">
        <img
          className="animate-img-1 top_image_bounce"
          src="assets/img/banner/2.png"
          alt="img"
        />
        <div className="container" style={{ maxWidth: '1200px' }} >
          <div className="section-title text-center">
            <h6 className="sub-title">Our Gallery</h6>
            <h2 className="title">
              Some Of  <span>Our Work</span>
            </h2>
          </div>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-tabs1"
              role="tabpanel"
              aria-labelledby="nav-tabs1-tab"
            >
              <div className="row">
                {data.slice(0, 3).map((data, index) => (
                  <div className="col-md-4" key={data.id}>
                    <div className="single-project-inner">
                      {data.attributes.Image ? (
                        <img
                          src={data.attributes.Image?.data?.attributes?.url ? IMAGE_URL + data.attributes.Image?.data?.attributes?.url : ""}
                          alt="Team Member"
                          style={{
                            height: '250px',
                            width: '100%',
                            maxWidth: '500px',
                            borderRadius: '10px',
                          }} />

                      ) : null}
                      <div className="details">
                        <span>
                          <FaPlus />
                        </span>
                        <Link className="read-more-text" to={`/project-details/${data.attributes.slug}`}>
                          {data.attributes.Title}
                        </Link>
                      </div>
                    </div>
                  </div>

                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-lg-4 col-md-6">
              <div className="single-case-study-inner">
                <div className="thumb">
                  <img src="assets/img/case-study/2.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/case-study-details">Tecnology Farms</Link>
                  </h5>
                  <Link className="cat" to="#">
                    Las vegas
                  </Link>
                  <Link className="right-arrow" to="#">
                    <FaAngleDoubleRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-case-study-inner">
                <div className="thumb">
                  <img src="assets/img/case-study/3.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/case-study-details">Tecnology Farms</Link>
                  </h5>
                  <Link className="cat" to="#">
                    Las vegas
                  </Link>
                  <Link className="right-arrow" to="#">
                    <FaAngleDoubleRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* ================== case-study area end ==================*/}
    </>
  );
};

export default CaseStudyAreaOne;
