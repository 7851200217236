import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  FaAngleLeft,
  FaAngleRight,
  FaCalendarAlt,
  FaRegFolderOpen,
  FaRegUser
} from "react-icons/fa";
import { apiBaseUrl, IMAGE_URL } from '../config/config';
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

const ContentRenderer = ({ content }) => {
  if (!content || content.length === 0) {
    return <p>No content available</p>;
  }

  return (
    <div style={{
      textAlign: 'justify',
      display: '-webkit-box',
      WebkitLineClamp: 4,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }}>
      {content.map((block, index) => {
        if (block.type === 'paragraph') {
          return (
            <p key={index}>
              {block.children.map((child, childIndex) => (
                <span key={childIndex}>{child.text}</span>
              ))}
            </p>
          );
        }
        return null;
      })}
    </div>
  );
};

const BlogGroup = () => {
  const [blogData, setBlogData] = useState([]);
  const [filteredBlogData, setFilteredBlogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null); // State for selected category
  const blogsPerPage = 2; // Number of blogs to display per page
  const [recentNews, setRecentNews] = useState([]); // State for recent news
  const [seo, setSeo] = useState({
    title: "Default Title",
    description: "Default Description",
  });

  const location = useLocation();  

 
  const fetchGlobalSEO = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "seos");
      const seoData = response.data.data;

     
      const currentPageSeo = seoData.find(
        (item) => item.attributes.Pages === getPageName()
      );
 
      const globalSeo = seoData.find(
        (item) => item.attributes.Pages === "Global"
      );
      if (currentPageSeo) {
        setSeo({
          title: currentPageSeo.attributes.Title || "Default Title",
          description: currentPageSeo.attributes.Desc || "Default Description",
        });
      } else if (globalSeo) {
  
        setSeo({
          title: globalSeo.attributes.Title || "Default Title",
          description: globalSeo.attributes.Desc || "Default Description",
        });
      } else {

        setSeo({
          title: "Default Title",
          description: "Default Description",
        });
      }
    } catch (error) {
      console.error("SEO fetch error:", error);
    }
  };

 
  const getPageName = () => {
    if (location.pathname === "/blog") return "Blogs";
   
  };

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(apiBaseUrl + "blog-page-contents?populate=*");
        setBlogData(response.data.data);
        setFilteredBlogData(response.data.data); // Initialize filteredBlogData
        console.log("Fetched blog data:", response);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    const fetchRecentNews = async () => {
      try {
        const response = await axios.get(apiBaseUrl + "blog-page-contents?populate=*");
        setRecentNews(response.data.data); // Set recent news data
      } catch (error) {
        console.error('Error fetching recent news:', error);
      }
    };

    fetchBlogData();
    fetchRecentNews();
    fetchGlobalSEO()
  }, []);

  // Filter blogs based on selected category
  useEffect(() => {
    if (selectedCategory) {
      const filtered = blogData.filter(blog =>
        blog.attributes.Category === selectedCategory
      );
      setFilteredBlogData(filtered);
    } else {
      setFilteredBlogData(blogData);
    }
    setCurrentPage(1); // Reset pagination to page 1 when filtering
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top on category change
  }, [selectedCategory, blogData]);

  // Handle search functionality
  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredBlogData(blogData);
    } else {
      const filtered = blogData.filter(blog =>
        blog.attributes.Title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredBlogData(filtered);
    }
    setCurrentPage(1);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top on search change
  }, [searchQuery, blogData]);

  // Calculate indices for slicing blogData
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogData.slice(indexOfFirstBlog, indexOfLastBlog);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top on page change
  };

  // Calculate total pages
  const totalPages = Math.ceil(filteredBlogData.length / blogsPerPage);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
  };

  // Get unique categories with counts
  const getCategoryCounts = () => {
    const categoryCounts = {};
    blogData.forEach(blog => {
      const category = blog.attributes.Category;
      categoryCounts[category] = (categoryCounts[category] || 0) + 1;
    });
    return categoryCounts;
  };

  const categories = getCategoryCounts();

  return (
    <>
    <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <div className='blog-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              {currentBlogs.length > 0 ? (
                currentBlogs.map((blog) => {
                  const { SingleBlog, Author, Icon, Date: blogDate, Title, slug, BlogDescription } = blog.attributes;
                  const formattedDate = new Date(blogDate);
                  const displayDate = formattedDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short' }).toUpperCase();

                  return (
                    <div className='single-blog-inner' key={blog.id}>
                      <div className='thumb'>
                        <img src={IMAGE_URL + SingleBlog?.data?.attributes?.url} alt='img' />
                        <span className='date'>{displayDate}</span>
                      </div>
                      <div className='details'>
                        <ul className='blog-meta'>
                          <li>
                            <FaRegUser /> {Author}
                          </li>
                          <li>
                            <FaRegFolderOpen /> {blog.attributes.Category}
                          </li>
                        </ul>
                        <h2 className='title'>
                          <Link to={`/blog-details/${slug}`}>
                            {Title}
                          </Link>
                        </h2>
                        {/* Render BlogDescription */}
                        <ContentRenderer content={BlogDescription || []} />
                        <Link className='btn btn-border-base mt-3' to={`/blog-details/${slug}`}>
                          Read More <FaAngleRight />
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No blogs found</p>
              )}
              <div className='pagination'>
                {currentPage > 1 && (
                  <Link className='prev page-numbers' to="#" onClick={() => paginate(currentPage - 1)}>
                    <FaAngleLeft />
                  </Link>
                )}
                {[...Array(totalPages).keys()].map(number => (
                  <Link
                    key={number + 1}
                    className={`page-numbers ${currentPage === number + 1 ? 'current' : ''}`}
                    to="#"
                    onClick={() => paginate(number + 1)}
                  >
                    {number + 1}
                  </Link>
                ))}
                {currentPage < totalPages && (
                  <Link className='next page-numbers' to="#" onClick={() => paginate(currentPage + 1)}>
                    <FaAngleRight />
                  </Link>
                )}
              </div>
            </div>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar'>
                <div className='widget widget_search'>
                  <form className='search-form' onSubmit={handleSearchSubmit}>
                    <div className='form-group'>
                      <input
                        type='text'
                        placeholder='Key word'
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                    <button className='submit-btn' type='submit'>
                      <FaAngleRight />
                    </button>
                  </form>
                </div>
                <div className='widget widget-recent-post'>
                  <h4 className='widget-title'>Recent News</h4>
                  <ul>
                    {recentNews.length > 0 ? (
                      recentNews.map((newsItem) => {
                        const { Title, Date: newsDate, slug } = newsItem.attributes;
                        const formattedDate = new Date(newsDate);
                        const displayDate = formattedDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short' }).toUpperCase();

                        return (
                          <li key={newsItem.id}>
                            <div className='media'>
                              <div className='media-left'>
                                <img src={IMAGE_URL + newsItem.attributes.SingleBlog?.data?.attributes?.url} alt='blog' style={{ width: "100px", height: "70px" }} />
                              </div>
                              <div className='media-body align-self-center'>
                                <h6 className='title'>
                                  <Link to={`/blog-details/${slug}`}>
                                    {Title}
                                  </Link>
                                </h6>
                                <div className='post-info'>
                                  <FaCalendarAlt />
                                  <span>{displayDate}</span>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <li>No recent news found</li>
                    )}
                  </ul>
                </div>
                <div className='widget widget_catagory'>
                  <h4 className='widget-title'>Category</h4>
                  <ul>
                    <li key="all" onClick={() => setSelectedCategory(null)}>
                      <Link to="#">
                        All <span>({blogData.length})</span>
                      </Link>
                    </li>

                    {Object.entries(categories).map(([category, count]) => (
                      <li key={category}>
                        <Link to="#" onClick={() => setSelectedCategory(category)}>
                          {category} <span>({count})</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogGroup;
