import React, { useState } from 'react';
import axios from 'axios';
import { apiBaseUrl } from '../config/config';
import { toast, Toaster } from 'react-hot-toast';
import emailjs from 'emailjs-com';

const ApplyModal = ({ job, onClose }) => {
    const [formData, setFormData] = useState({
        First_Name: '',
        Last_Name: '',
        Email: '',
        Upload_Resume: null,
        Position: '',
        Additional: ''
    });
    const [error, setError] = useState('');

    const validateName = (name) => /^[a-zA-Z]+$/.test(name); // Only letters allowed

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'Upload_Resume' && files[0]) {
            const file = files[0];
            const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/rtf'];
            const maxSize = 1 * 1024 * 1024; // 1MB

            if (!allowedTypes.includes(file.type)) {
                setError('Your CV must be a .doc, .pdf, .docx, or .rtf file.');
                return;
            }

            if (file.size > maxSize) {
                setError('Your CV must be no larger than 1MB.');
                return;
            }

            setError('');
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: files ? files[0] : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate First_Name and Last_Name
        if (!validateName(formData.First_Name)) {
            toast.error('First name should contain only letters.');
            return;
        }

        if (!validateName(formData.Last_Name)) {
            toast.error('Last name should contain only letters.');
            return;
        }

        if (error) {
            toast.error(error);
            return;
        }

        const data = new FormData();

        const jsonData = JSON.stringify({
            First_Name: formData.First_Name,
            Last_Name: formData.Last_Name,
            Email: formData.Email,
            Additional: formData.Additional,
            Position: job.attributes.title,
            jobPosting: job.id,
            Applied_At: new Date().toISOString()
        });

        data.append('data', jsonData);
        data.append('files.Upload_Resume', formData.Upload_Resume);

        try {
            await axios.post(apiBaseUrl + `job-applications`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const emailParams = {
                from_name: formData.First_Name + ' ' + formData.Last_Name,
                to_name: 'IgnitersHub',
                job_title: job.attributes.title,
                applicant_email: formData.Email,
            };

            emailjs
                .send(
                    'service_z2fkdpb',
                    'template_1rp6x1j',
                    emailParams,
                    'f23lqZQNdoXVxUhvs'
                )
                .then((result) => {
                    if (result.text === 'OK') {
                        toast.success('Application submitted and email sent successfully!');
                    }
                }, (error) => {
                    console.error('EmailJS error:', error);
                    toast.error('Failed to send the email.');
                });

            onClose();
        } catch (error) {
            console.error('Error submitting application:', error.response?.data || error.message);
            toast.error('Failed to submit application. Please try again.');
        }
    };

    const modalStyles = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'white',
        padding: '20px',
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)',
        zIndex: 1000,
        width: '90%',
        maxWidth: '550px',
        borderRadius: '8px',
    };

    const overlayStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.6)',
        zIndex: 999,
    };

    const inputStyles = {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #ccc',
    };

    const labelStyles = {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    };

    const textareaStyles = {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #ccc',
        resize: 'vertical',
    };

    const buttonStyles = {
        display: 'inline-block',
        padding: '10px 20px',
        margin: '10px 5px 0 0',
        background: '#007BFF',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
    };

    const closeButtonStyles = {
        ...buttonStyles,
        background: '#6c757d',
    };

    return (
        <div>
            <Toaster position="bottom-center" reverseOrder={false} />
            <div style={overlayStyles} onClick={onClose}>
                <div style={modalStyles} onClick={(e) => e.stopPropagation()}>
                    <h2>Apply for {job.attributes.title}</h2>
                    <form onSubmit={handleSubmit}>
                        <label style={labelStyles}>
                            First Name:
                            <input
                                type="text"
                                name="First_Name"
                                value={formData.First_Name}
                                onChange={handleChange}
                                style={inputStyles}
                                required
                            />
                        </label>
                        <label style={labelStyles}>
                            Last Name:
                            <input
                                type="text"
                                name="Last_Name"
                                value={formData.Last_Name}
                                onChange={handleChange}
                                style={inputStyles}
                                required
                            />
                        </label>
                        <label style={labelStyles}>
                            Email:
                            <input
                                type="email"
                                name="Email"
                                value={formData.Email}
                                onChange={handleChange}
                                style={inputStyles}
                                required
                            />
                        </label>
                        <label style={labelStyles}>
                            Resume:
                            <input
                                type="file"
                                name="Upload_Resume"
                                onChange={handleChange}
                                style={inputStyles}
                                required
                            />
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </label>
                        <button type="submit" style={buttonStyles}>Apply Now</button>
                        <button type="button" onClick={onClose} style={closeButtonStyles}>Close</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ApplyModal;
