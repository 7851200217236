import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { apiBaseUrl } from '../config/config';
import { IMAGE_URL } from '../config/config';
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

const ProjectAreaOne = () => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeCategory, setActiveCategory] = useState('View All');
  const [seo, setSeo] = useState({
    title: "Default Title",
    description: "Default Description",
  });

  const location = useLocation();

  const fetchGlobalSEO = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "seos");
      const seoData = response.data.data;

      const currentPageSeo = seoData.find(
        (item) => item.attributes.Pages === getPageName()
      );
      const globalSeo = seoData.find(
        (item) => item.attributes.Pages === "Global"
      );

      if (currentPageSeo) {
        setSeo({
          title: currentPageSeo.attributes.Title || "Default Title",
          description: currentPageSeo.attributes.Desc || "Default Description",
        });
      } else if (globalSeo) {
        setSeo({
          title: globalSeo.attributes.Title || "Default Title",
          description: globalSeo.attributes.Desc || "Default Description",
        });
      } else {
        setSeo({
          title: "Default Title",
          description: "Default Description",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPageName = () => {
    if (location.pathname === "/project") return "Projects";
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "galleries?populate=*");
      const projectData = response.data.data;

      setData(projectData);
      setFilteredData(projectData); // Initialize with all projects

      // Extract unique categories from the project data
      const allCategories = projectData.map(item => item.attributes.Category);
      const uniqueCategories = ['View All', ...new Set(allCategories)];
      setCategories(uniqueCategories);

      console.log("Project", response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchGlobalSEO();
  }, [location]);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    if (category === 'View All') {
      setFilteredData(data); // Show all projects when "All" is selected
    } else {
      const filteredProjects = data.filter(item => item.attributes.Category === category);
      setFilteredData(filteredProjects);
    }
  };

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <div className="project-section project-section_1 pd-top-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h6 className="sub-title">Our Gallery</h6>
                <h2 className="title">
                  Some Of Our<span> Work! </span>
                </h2>
              </div>
            </div>
          </div>
          <nav className="text-center">
            <div
              className="nav nav-tabs project-nav-tab d-inline-flex mb-5"
              id="nav-tab"
              role="tablist"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              {categories.map((category) => (
                <button
                  key={category}
                  className={`nav-link ${activeCategory === category ? 'active' : ''}`}
                  onClick={() => handleCategoryClick(category)}
                  style={{
                    padding: '10px 15px',
                    fontSize: '16px',
                    whiteSpace: 'nowrap',
                    borderRadius: '5px',
                    ...(activeCategory === category ? { backgroundColor: '#007bff', color: '#fff' } : {}),
                  }}
                >
                  {category}
                </button>
              ))}
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-tabs1"
              role="tabpanel"
              aria-labelledby="nav-tabs1-tab"
            >
              <div className="row">
                {filteredData.map((item) => (
                  <div className="col-md-4" key={item.id}>
                    <div className="single-project-inner">
                      {item.attributes.Image ? (
                        <img
                          src={item.attributes.Image?.data?.attributes?.url ? IMAGE_URL + item.attributes.Image?.data?.attributes?.url : ""}
                          alt="Project"
                          style={{ height: 290, width: 700, borderRadius: 10 }}
                        />
                      ) : null}
                      <div className="details">
                        <span>
                          <FaPlus />
                        </span>
                        <Link className="read-more-text" to={`/project-details/${item.attributes.slug}`}>
                          {item.attributes.Title}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectAreaOne;
