import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { IMAGE_URL } from '../config/config';
// import HomeEight from '../../pages/homePages/HomeEight';


const SingleBrandV1 = ({ url }) => {
    // const { thumb } = brand;

    return (
        <>
            <div className="logo-item">
                <Link >
                    {url ? (
                        <img src={url ? IMAGE_URL + url : ""} alt="Logo" style={{ height: 100 }} />
                    ) : null}
                </Link>
            </div>
        </>
    );
};

export default SingleBrandV1;