import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import CaseStudyArea from '../components/CaseStudyArea';
import FooterOne from '../components/FooterOne';
import NavBar from '../components/NavBar';
import axios from 'axios';
import { apiBaseUrl } from '../config/config';
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

const ProjectDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);  
  const [header, setHeader] = useState(null);  
  const [seo, setSeo] = useState({
    title: "Default Title",
    description: "Default Description",
  });

  const location = useLocation();  

 
  const fetchGlobalSEO = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "seos");
      const seoData = response.data.data;

     
      const currentPageSeo = seoData.find(
        (item) => item.attributes.Pages === getPageName()
      );
 
      const globalSeo = seoData.find(
        (item) => item.attributes.Pages === "Global"
      );
      if (currentPageSeo) {
        setSeo({
          title: currentPageSeo.attributes.Title || "Default Title",
          description: currentPageSeo.attributes.Desc || "Default Description",
        });
      } else if (globalSeo) {
  
        setSeo({
          title: globalSeo.attributes.Title || "Default Title",
          description: globalSeo.attributes.Desc || "Default Description",
        });
      } else {

        setSeo({
          title: "Default Title",
          description: "Default Description",
        });
      }
    } catch (error) {
      console.error("SEO fetch error:", error);
    }
  };

 
  const getPageName = () => {
    if (location.pathname.includes("/project")) return "Projects";
   
  };

  // Fetch header data
  const fetchHeader = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "headers?populate=*");
      setHeader(response.data.data[0].attributes);
    } catch (error) {
      console.error("Header fetch error:", error);
    }
  };

  // Fetch specific project data by ID
  const fetchData = async (id) => {
    try {
      const response = await axios.get(apiBaseUrl + `galleries/${id}?populate=*`);
      console.log("Portfolio data:", response.data);
      setData(response.data.data);
    } catch (error) {
      console.error("Data fetch error:", error);
    }
  };

  // Run when the component mounts or when `id` or `location` changes
  useEffect(() => {
    fetchHeader();  // Fetch header data
    fetchData(id);  // Fetch project-specific data based on the `id`
    fetchGlobalSEO();  // Fetch SEO data
    window.scrollTo(0, 0);  // Scroll to top when navigating to a new project
  }, [id, location]);  // Re-run when `id` or `location` changes

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>

      {/* Navigation Bar */}
      <NavBar header={header} />

      {/* Breadcrumb */}
      <Breadcrumb title={'Project Details'} />

      {/* Case Study Area */}
      <CaseStudyArea portfolioId={id} />

      {/* Footer */}
      {header && <FooterOne header={header} />}
    </>
  );
};

export default ProjectDetails;
