import React, { useState, useEffect } from 'react';
import AboutAreaOne from '../components/AboutAreaOne';
import Breadcrumb from '../components/Breadcrumb';
import ContactAreaOne from '../components/ContactAreaOne';
import CounterAreaOne from '../components/CounterAreaOne';
import FaqAreaOne from '../components/FaqAreaOne';
import FooterOne from '../components/FooterOne';
import NavBar from '../components/NavBar';
import TeamAreaOne from '../components/TeamAreaOne';
import WorkProcessOne from '../components/WorkProcessOne';
import ServiceAreaOne from '../components/ServiceAreaOne';
import { apiBaseUrl } from '../config/config';
import axios from 'axios';
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

const About = () => {
  const [header, setHeader] = useState([]);
  const [seo, setSeo] = useState({
    title: "Default Title",
    description: "Default Description",
  });

  const location = useLocation();

  const fetchGlobalSEO = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "seos");
      const seoData = response.data.data;

      const currentPageSeo = seoData.find(
        (item) => item.attributes.Pages === getPageName()
      );
      const globalSeo = seoData.find(
        (item) => item.attributes.Pages === "Global"
      );

      if (currentPageSeo) {
        setSeo({
          title: currentPageSeo.attributes.Title || "Default Title",
          description: currentPageSeo.attributes.Desc || "Default Description",
        });
      } else if (globalSeo) {
        setSeo({
          title: globalSeo.attributes.Title || "Default Title",
          description: globalSeo.attributes.Desc || "Default Description",
        });
      } else {
        setSeo({
          title: "Default Title",
          description: "Default Description",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPageName = () => {
    if (location.pathname === "/about") return "About us";
  };

  const fetchHeader = async () => {
    try {
      const response = await axios.get(
        apiBaseUrl + "headers?populate=*"
      );
      console.log("qqqq", response)
      setHeader(response.data.data[0].attributes);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchHeader();
    fetchGlobalSEO()
    window.scrollTo(0, 0);
    //fetchAboutData();
  }, [location])

  return (
    <>
    <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      {/* Navigation Bar */}
      <NavBar header={header} />

      {/* Navigation Bar */}
      <Breadcrumb title={'About Us'} />

      {/* About Area One */}
      <AboutAreaOne />

      {/* ServiceAreaOne */}
      <ServiceAreaOne />

      {/* FAQ Area One */}
      {/* <FaqAreaOne /> */}

      {/* Team Area One */}
      <TeamAreaOne />

      {/* Counter Area One */}
      <CounterAreaOne />

      {/* Contact Area One */}
      <ContactAreaOne />

      {/* Work Process One */}
      {/* <WorkProcessOne /> */}

      {/* Footer One */}
      <FooterOne header={header} />
    </>
  );
};

export default About;
