import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import PricingAreaOne from "../components/PricingAreaOne";
import ServiceAreaGroupThree from "../components/ServiceAreaGroupThree";
import axios from "axios";
import { apiBaseUrl } from "../config/config";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

const ServiceThree = () => {
  const [header, setHeader] = useState([]);
  const [seo, setSeo] = useState({
    title: "Default Title",
    description: "Default Description",
  });

  const location = useLocation();

  const fetchGlobalSEO = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "seos");
      const seoData = response.data.data;
      console.log(seoData)

      const currentPageSeo = seoData.find(
        (item) => item.attributes.Pages === getPageName()
      );
      const globalSeo = seoData.find(
        (item) => item.attributes.Pages === "Global"
      );

      if (currentPageSeo) {
        setSeo({
          title: currentPageSeo.attributes.Title || "Default Title",
          description: currentPageSeo.attributes.Desc || "Default Description",
        });
      } else if (globalSeo) {
        setSeo({
          title: globalSeo.attributes.Title || "Default Title",
          description: globalSeo.attributes.Desc || "Default Description",
        });
      } else {
        setSeo({
          title: "Default Title",
          description: "Default Description",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPageName = () => {
    if (location.pathname === "/service") return "Services";
  };

  const fetchHeader = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "headers?populate=*");
      setHeader(response.data.data[0].attributes);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchHeader();
    fetchGlobalSEO();
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      {/* Navigation Bar */}
      <NavBar header={header} />

      {/* Navigation Bar */}
      <Breadcrumb title={"Services"} />

      {/* ServiceAreaGroupThree */}
      <ServiceAreaGroupThree />

      {/* Pricing Area One */}
      {/* <PricingAreaOne /> */}
      <br></br>
      <br></br>
      <br></br>

      {/* Footer One */}
      <FooterOne header={header} />
    </>
  );
};

export default ServiceThree;
