import React, { useEffect, useState } from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { apiBaseUrl, IMAGE_URL } from "../config/config";
import Helmet from "react-helmet";

const TeamAreaGroupThree = () => {
  const [team, setTeam] = useState([]);
  const [seo, setSeo] = useState({ title: "Default Title", description: "Default Description" });

  const location = useLocation();

  useEffect(() => {
    fetchGlobalSEO();
    fetchTeam()
    window.scrollTo(0, 0);
  }, [location]);

  const fetchGlobalSEO = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "seos");
      const seoData = response.data.data;

      const currentPageSeo = seoData.find(item => item.attributes.Pages === getPageName());
      const globalSeo = seoData.find(item => item.attributes.Pages === "Global");

      if (currentPageSeo) {
        setSeo({
          title: currentPageSeo.attributes.Title || "Default Title",
          description: currentPageSeo.attributes.Desc || "Default Description",
        });
      } else if (globalSeo) {
        setSeo({
          title: globalSeo.attributes.Title || "Default Title",
          description: globalSeo.attributes.Desc || "Default Description",
        });
      } else {
        setSeo({
          title: "Default Title",
          description: "Default Description",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPageName = () => {
    if (location.pathname === "/team")
      return "Team";

  };

  const fetchTeam = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "team-members?populate=*");
      const teamData = response.data.data;
      setTeam(teamData);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>

      <div className="team-area bg-relative pd-top-120 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-title">
                <h6 className="sub-title">Team Members</h6>
                <h2 className="title">Our Awesome Team</h2>
              </div>
            </div>
          </div>
          <div className="team-blog-area pd-top-120 pd-bottom-90">
            <div className="container">
              <div className="team-details-page">
                {team.map((teamMember) => (
                  <div key={teamMember.id} className="row " style={{ marginBottom: "100px" }}>
                    <div className="col-lg-5 text-center">
                      <div className="thumb mb-4 mb-lg-0" >
                        <img
                          src={
                            teamMember?.attributes?.Image?.data?.attributes?.url
                              ? IMAGE_URL + teamMember.attributes.Image.data.attributes.url
                              : ""
                          }
                          alt={teamMember.attributes.Name}
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 align-self-center ps-xl-5" >
                      <h3 className="mb-2">{teamMember?.attributes?.Name}</h3>
                      <span className="designation">{teamMember?.attributes?.Designation}</span>
                      <div className="details mt-4">
                        <p style={{ textAlign: "justify" }}>{teamMember?.attributes?.Description}</p>
                      </div>
                      <ul className="social-media style-base mt-4 pt-2">
                        {teamMember?.attributes?.Linkedin && (
                          <li>
                            <Link to={teamMember.attributes.Linkedin} target="_blank">
                              <FaLinkedinIn aria-hidden="true" />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamAreaGroupThree;
