import React, { useState, useEffect } from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { apiBaseUrl, IMAGE_URL } from '../config/config';

const TeamAreaOne = () => {
  const [team, setTeam] = useState([]);

  useEffect(() => {
    fetchTeam();
  }, []);

  const fetchTeam = async () => {
    try {
      const response = await axios.get(apiBaseUrl + 'team-members?populate=*');
      setTeam(response.data.data);
      console.log("team::", response);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/*====================== team area start ======================*/}
      <div className="team-area bg-relative pd-top-90 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">Team Members</h6>
            <h2 className="title">Our Awesome Team</h2>
          </div>
          <div className="row justify-content-center">
            {team.map((member) => (
              <div key={member.id} className="col-lg-4 col-md-6">
                <div
                  className="single-team-inner text-center border-radius-5 bg-white"
                  style={{
                    overflow: 'hidden',
                    padding: '20px',
                    borderRadius: '5px',
                  }}
                >
                  <div
                    className="thumb"
                    style={{
                      position: 'relative',
                      marginBottom: '20px',
                    }}
                  >
                    {member.attributes.Image ? (
                      <img
                        src={member.attributes.Image?.data?.attributes?.url ? IMAGE_URL + member.attributes.Image?.data?.attributes?.url : ""}
                        alt="Team Member"
                        style={{
                          width: '200px',
                          height: '250px',
                          objectFit: 'cover',
                          borderRadius: '5px',
                          paddingTop: '35px',
                        }}
                      />
                    ) : null}
                    <ul
                      className="team-social-inner"
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        display: 'flex',
                        gap: '10px',
                      }}
                    >
                      {/* {member.attributes.Facebook && ( */}
                      {/* <li>
                        <Link to={member.attributes.Facebook} target='_blank'>
                          <FaFacebookF />
                        </Link>
                      </li> */}
                      {/* )} */}
                      {/* {member.attributes.Twitter && ( */}
                      {/* <li>
                        <Link to={member.attributes.Twitter} target='_blank'>
                          <FaTwitter />
                        </Link>
                      </li> */}
                      {/* )} */}
                      {/* {member.attributes.Linkedin && ( */}
                      <li>
                        <Link to={member.attributes.Linkedin} target='_blank'>
                          <FaLinkedinIn />
                        </Link>
                      </li>
                      {/* )} */}
                      {/* {member.attributes.Instagram && ( */}
                      {/* <li>
                        <Link to={member.attributes.Instagram} target='_blank'>
                          <FaInstagram />
                        </Link>
                      </li> */}
                      {/* )} */}
                    </ul>
                  </div>
                  <div className="details">
                    <h5 style={{ fontWeight: '700' }}>
                      <Link to={`/team-details/${member.attributes.slug}`}>{member.attributes.Name}</Link>
                    </h5>
                    <span >{member.attributes.Designation}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* ====================== team area end ======================*/}
    </>
  );
};

export default TeamAreaOne;
