import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ApplyModal from "../components/ApplyModal";
import { apiBaseUrl } from '../config/config';
import { toast, Toaster } from 'react-hot-toast';
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";


const CareerArea = () => {
  const [jobPostings, setJobPostings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postingsPerPage = 3;
  const [seo, setSeo] = useState({
    title: "Default Title",
    description: "Default Description",
  });

  const location = useLocation();

  const fetchGlobalSEO = async () => {
    try {
      const response = await axios.get(apiBaseUrl + "seos");
      const seoData = response.data.data;

      const currentPageSeo = seoData.find(
        (item) => item.attributes.Pages === getPageName()
      );
      const globalSeo = seoData.find(
        (item) => item.attributes.Pages === "Global"
      );

      if (currentPageSeo) {
        setSeo({
          title: currentPageSeo.attributes.Title || "Default Title",
          description: currentPageSeo.attributes.Desc || "Default Description",
        });
      } else if (globalSeo) {
        setSeo({
          title: globalSeo.attributes.Title || "Default Title",
          description: globalSeo.attributes.Desc || "Default Description",
        });
      } else {
        setSeo({
          title: "Default Title",
          description: "Default Description",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPageName = () => {
    if (location.pathname === "/career") return "Career";
  };

  useEffect(() => {
    axios.get(apiBaseUrl + "job-postings")
      .then(response => {
        const postings = response.data?.data ?? [];
        postings.sort((a, b) => new Date(b.attributes.datePosted) - new Date(a.attributes.datePosted));
        setJobPostings(postings);
      })
      .catch(error => {
        console.error('Error fetching job postings:', error);
      });
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the currentPage changes
    fetchGlobalSEO()
    window.scrollTo(0, 0);
  }, [currentPage,location]);

  const handleApplyClick = (job) => {
    setSelectedJob(job);
    setShowModal(true);
  };

  const formatText = (text) => {
    return text.split('\n').map((line, index) => {
      const parts = line.split(/(:\s+)/); // Split on colon and keep the colon
      return (
        <React.Fragment key={index}>
          {parts.map((part, partIndex) =>
            part.endsWith(':') ? (
              <strong style={{ color: "black" }} key={partIndex}>{part}</strong>
            ) : (
              part
            )
          )}
        </React.Fragment>
      );
    });
  };

  const jobListingStyles = {
    border: '1px solid #ddd',
    padding: '20px',
    marginBottom: '50px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  };

  const buttonStyles = {
    display: 'inline-block',
    padding: '10px 20px',
    background: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  };

  const paginatePostings = (postings) => {
    const startIndex = (currentPage - 1) * postingsPerPage;
    const endIndex = startIndex + postingsPerPage;
    return postings.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(jobPostings.length / postingsPerPage);

  return (
    <>
    <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
    <div style={{ margin: '120px auto', width: '90%', paddingBottom: "10px" }}>
      <Toaster position="bottom-center" reverseOrder={false} />

      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {paginatePostings(jobPostings).map(job => (
          <li key={job.id} style={jobListingStyles}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <h2>{job.attributes.title}</h2>
              <button style={buttonStyles} onClick={() => handleApplyClick(job)}>Apply Now</button>
            </div>
            <h6 style={{ marginBottom: '5px' }}><strong>Location:</strong> {job.attributes.location}</h6>
            <h6 style={{ marginBottom: '5px' }}><strong>Experience:</strong> {job.attributes.experience}</h6>
            <h6 style={{ marginBottom: '15px' }}><strong>Date Posted:</strong> {job.attributes.datePosted}</h6>
            {job.attributes.description.map((desc, index) => (
              <p key={index} style={{ textAlign: 'justify' }}>
                {desc.children.map((child, childIndex) => (
                  <React.Fragment key={childIndex}>
                    {formatText(child.text)}
                  </React.Fragment>
                ))}
              </p>
            ))}
          </li>
        ))}
      </ul>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <button
          style={{ ...buttonStyles, marginRight: '10px' }}
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(prev => prev - 1)}
        >
          Previous
        </button>
        <span style={{ marginTop: "10px" }}>Page {currentPage} of {totalPages}</span>
        <button
          style={{ ...buttonStyles, marginLeft: '10px' }}
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(prev => prev + 1)}
        >
          Next
        </button>
      </div>
      {showModal && <ApplyModal job={selectedJob} onClose={() => setShowModal(false)} />}
    </div>
    </>
  );
};

export default CareerArea;
